import React, { useEffect, useState } from "react";
import NoFavorite from "./shared/NoFavorite";
import CreatorCard from "pages/videoPage/shared/CreatorCard";
import ArtistSkeleton from "components/skeleton/ArtistSkeleton";
import { ArtistCard } from "pages/musicPage/shared/ArtistCard";

function UserSubscribedPage({
  favoriteCreatorIds,
  favoriteData,
  user,
  isLoading,
}: any) {
  const [subscribed, setSubscribed] = useState<any>([]);
  const followed = favoriteData?.favorite_artists || [];

  useEffect(() => {
    setSubscribed(followed);
  }, []);

  return (
    <>
      {!isLoading && (
        <div>
          {/* top section */}
          <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h1 className="text-lg font-medium text-white lg:text-xl md:text-2xl">
                {/* Videos by booster */}
                Stars
              </h1>
            </div>
          </div>
          {/* card section */}
          {subscribed.length === 0 && subscribed ? (
            <div>
              <NoFavorite
                text="Stars"
                user={user}
                // subscribed={true}
              />
            </div>
          ) : (
            <div>
              <div className="grid w-[95%] grid-cols-2 pl-6 mt-4 mb-5 max-sm:pl-0 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-0 max-sm:w-full max-sm:gap-3 ">
                {subscribed.length > 0 &&
                  subscribed.map((artist: any, i: number) => (
                    <div key={i}>
                      <ArtistCard artist={artist} />
                    </div>
                    // <div key={i}>
                    //   <CreatorCard creator={creator} />
                    // </div>
                  ))}
              </div>
            </div>
            // <>
            //   {/* coursel */}
            //   <div className="">
            //     <Swiper
            //       slidesPerView={5}
            //       breakpoints={{
            //         0: {
            //           slidesPerView: 2,
            //         },
            //         370: {
            //           slidesPerView: 2,
            //         },
            //         700: {
            //           slidesPerView: 3,
            //         },
            //         1100: {
            //           slidesPerView: 4,
            //         },
            //         1200: {
            //           slidesPerView: 5,
            //         },
            //         1500: {
            //           slidesPerView: 6,
            //         },
            //         1600: {
            //           slidesPerView: 7,
            //         },
            //       }}
            //       modules={[Pagination]}
            //       className="mt-4 mb-5 mySwiper"
            //     >
            //       {podcasts.length > 0 &&
            //         podcasts.map((podcasts: any, i: number) => (
            //           <div key={i}>
            //             <SwiperSlide className="">
            //               <Podcastcard
            //                 podcast={podcasts}
            //                 user={user}
            //                 favorite={favoritePodcastsIds}
            //               />
            //             </SwiperSlide>
            //           </div>
            //         ))}
            //     </Swiper>
            //   </div>
            // </>
          )}
        </div>
      )}
      {isLoading && <ArtistSkeleton />}
    </>
  );
}

export default UserSubscribedPage;
