import SubText from "components/shared/SubText";
import RadioCard from "pages/radioPage/shared/RadioCard";
import { useSelector } from "react-redux";

function CreatorHomeRadioGrid({ radios, setIsSelected, onClick }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const handleSelectSeeAll = () => {
    setIsSelected(7);
    if (onClick) onClick();
  };

  return (
    <div>
      {/* card section */}

      {radios?.length !== 0 && (
        <>
          <div className="flex justify-between mt-10 ml-3 max-sm:ml-0">
            <SubText text="Radio" className="font-bold" />
            {radios?.length > 3 && (
              <p
                onClick={handleSelectSeeAll}
                className="font-[500] text-[15px] text-[#F50537] cursor-pointer mr-10 max-sm:mr-3"
              >
                See all
              </p>
            )}
          </div>

          <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3  max-sm:mx-auto max-sm:w-full max-sm:gap-4">
            {radios?.length > 0 &&
              radios?.slice(0, 3)?.map((station: any, i: any) => (
                <div key={i} className="max-sm:ml-[-15px] ml-3">
                  <RadioCard station={station} user={loggedInUser} />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}

export default CreatorHomeRadioGrid;
