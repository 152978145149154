import { useContext, useEffect, useState } from "react";
import { AllChatData } from "constant/ChatData";
import AllChats from "./AllChats";
import OpenChat from "./OpenChat";
import { ChatContext } from "context/ChatContext";
import { IUserChat } from "types/IUserChat";
import { useSelector } from "react-redux";

function UserChatDrawer(props: IUserChat) {
  const { openChatDrawer } = useSelector((state: any) => state?.userChat);
  const { selectedChat, setSelectedChat } = useContext(ChatContext);
  
  useEffect(() => {
    if (!openChatDrawer) {
      setSelectedChat(null);
    }
  }, [openChatDrawer]);

  return (
    <div className="bg-[var(--Color1E1E1E)] !z-50">
      {!selectedChat ? (
        <>
          <AllChats setSelectedChat={setSelectedChat} sidePanel={true}/>
        </>
      ) : (
        <>
          {/* <OpenChat sidePanel={true} />
           */}
          <OpenChat
            sidePanel={true}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
          />
        </>
      )}
    </div>
  );
}

export default UserChatDrawer;
