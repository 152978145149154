import SubText from "components/shared/SubText";
import "swiper/css/navigation";
import "swiper/css";
import CreatorVideoCard from "./CreatorVideoCard";
import CreatorVideoSkeleton from "components/skeleton/CreatorVideoSkeleton";
import NoSearch from "pages/searchPage/shared/NoSearch";

function CreatorHomeVideoGrid({
  creatorData,
  isLoading,
  handleCreatorVideoShowMore,
}: any) {
  const recent_videos = creatorData?.recent_videos || [];
  console.log("recent>>>", recent_videos);
  return (
    <div>
      {!isLoading && (
        <>
          {recent_videos.length !== 0 && recent_videos && (
            <>
              <div className="flex justify-between mt-10 ml-3 max-sm:ml-0">
                <SubText text="Videos" className="font-bold" />
                {recent_videos?.length > 4 && (
                  <p
                    className="text-[#F50537] font-[500] text-[14px]  cursor-pointer mr-10 max-sm:mr-3"
                    onClick={handleCreatorVideoShowMore}
                  >
                    See All
                  </p>
                )}
              </div>
              <div className="mt-4 mb-5 w-[95%] grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 max-sm:mx-auto max-sm:w-full max-sm:gap-3">
                {recent_videos?.slice(0, 4).map((video: any, index: any) => (
                  <div key={index} className="mb-5 mr-4 max-sm:ml-[-15px] ml-0">
                    <CreatorVideoCard
                      video={video}
                      slug={creatorData?.general_info.slug}
                      // season={episode?.season}
                      // id={episode?.id}
                      isLoading={isLoading}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
      {isLoading && <CreatorVideoSkeleton />}
    </div>
  );
}

export default CreatorHomeVideoGrid;
