import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function JivoChatCustomization() {
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}${location.hash}`;
  const [btnClassName, setBtnClassName] = useState(".button_d794");
  const [isLoading, setIsLoading] = useState(true);

  const {
    playerVisible,
    radioVisible,
    podcastVisible,
    fullScreen,
    visibleQueue,
  } = useSelector((state: any) => state?.audio);
  const { openChatDrawer } = useSelector((state: any) => state?.userChat);

  // useEffect(() => {
  //   setTimeout(()=>{
  //     if (isLoading) {
  //       const divElements = Array.from(document.getElementsByTagName("jdiv"));
  //       console.log("divElements : ", divElements);
  //       const btnElements1 = divElements.filter((jdiv: any) =>
  //         jdiv.className.includes("button_")
  //       );

  //       if (btnElements1 && btnElements1?.length == 1) {
  //         setBtnClassName(`.${btnElements1[0]?.className}`);
  //         setIsLoading(false);
  //         console.log(`className : .${btnElements1[0]?.className}`);
  //       } else {
  //         const btnClasses = btnElements1.map((jdiv: any) => jdiv.className);
  //         const singleClassNames = btnClasses.filter(
  //           (btn: any) => btn.split(" ").length == 1
  //         );
  //         console.log("singleClassNames : ", singleClassNames);
  //         if (singleClassNames && singleClassNames?.length == 1) {
  //           setBtnClassName(`.${singleClassNames}`);
  //           setIsLoading(false);
  //         }
  //       }
  //     }
  //   },1000)
  // },[]);

  // useEffect(() => {
  //   // setTimeout(()=>{
  //     if (isLoading) {
  //       const divElements = Array.from(document.getElementsByTagName("jdiv"));
  //       console.log("divElements : ", divElements);
  //       const btnElements1 = divElements.filter((jdiv: any) =>
  //         jdiv.className.includes("button_")
  //       );

  //       if (btnElements1 && btnElements1?.length == 1) {
  //         setBtnClassName(`.${btnElements1[0]?.className}`);
  //         setIsLoading(false);
  //         console.log(`className : .${btnElements1[0]?.className}`);
  //       } else {
  //         const btnClasses = btnElements1.map((jdiv: any) => jdiv.className);
  //         const singleClassNames = btnClasses.filter(
  //           (btn: any) => btn.split(" ").length == 1
  //         );
  //         console.log("singleClassNames : ", singleClassNames);
  //         if (singleClassNames && singleClassNames?.length == 1) {
  //           setBtnClassName(`.${singleClassNames}`);
  //           setIsLoading(false);
  //         }
  //       }
  //     }
  //   // },1000)
  // },[fullScreen]);

  // useEffect(() => {
  //   const updateButtonClassName = () => {
  //     if (isLoading) {
  //       const divElements = Array.from(document.getElementsByTagName("jdiv"));
  //       console.log("divElements : ", divElements);
  //       const btnElements1 = divElements.filter((jdiv: any) =>
  //         jdiv.className.includes("button_")
  //       );

  //       if (btnElements1 && btnElements1.length === 1) {
  //         setBtnClassName(`.${btnElements1[0]?.className}`);
  //         setIsLoading(false);
  //         console.log(`className : .${btnElements1[0]?.className}`);
  //       } else {
  //         const btnClasses = btnElements1.map((jdiv: any) => jdiv.className);
  //         const singleClassNames = btnClasses.filter(
  //           (btn: any) => btn.split(" ").length === 1
  //         );
  //         console.log("singleClassNames : ", singleClassNames);
  //         if (singleClassNames && singleClassNames.length === 1) {
  //           setBtnClassName(`.${singleClassNames[0]}`);
  //           setIsLoading(false);
  //         }
  //       }
  //     }
  //   };

  //   const timer = setTimeout(updateButtonClassName, 5000);

  //   return () => clearTimeout(timer);
  // }, [fullScreen, isLoading]);

  useEffect(() => {
    if (isLoading) {
      const divElements = Array.from(document.getElementsByTagName("jdiv"));
      // console.log("divElements : ", divElements);
      const btnElements1 = divElements.filter((jdiv: any) =>
        jdiv.className.includes("button_")
      );

      if (btnElements1 && btnElements1.length === 1) {
        setBtnClassName(`.${btnElements1[0]?.className}`);
        setIsLoading(false);
        // console.log(`className : .${btnElements1[0]?.className}`);
      } else {
        const btnClasses = btnElements1.map((jdiv: any) => jdiv.className);
        const singleClassNames = btnClasses.filter(
          (btn: any) => btn.split(" ").length === 1
        );
        // console.log("singleClassNames : ", singleClassNames);
        if (singleClassNames && singleClassNames.length === 1) {
          setBtnClassName(`.${singleClassNames[0]}`);
          setIsLoading(false);
        }
      }
    }
  });

  return (
    <>
      {/* change player scale */}
      {playerVisible ||
      radioVisible ||
      podcastVisible ||
      currentUrl.includes("/messages") ? (
        <>
          {fullScreen ? (
            <></>
          ) : (
            <>
              <style>
                {`
            ${btnClassName} {
                scale: 0.7 !important;
                margin-top: -170px !important;
            `}
              </style>
            </>
          )}
        </>
      ) : (
        <>
          <style>
            {`
            ${btnClassName} {
                scale: 1 !important;   
                margin-top: -160px !important;         
                margin-right: 10px !important;         
            `}
          </style>
        </>
      )}

      {/* visible queue option */}
      {visibleQueue ? (
        <>
          <style>
            {`
            ${btnClassName} {
                display: none !important;    
                transition: all 2s ease-in-out;
            `}
          </style>
        </>
      ) : (
        <></>
      )}

      {/* hide chat icon from /watch pages */}
      {currentUrl.includes("/watch/") ||
      openChatDrawer ||
      currentUrl.includes("/messages/") ? (
        <>
          <style>
            {`
          ${btnClassName} {
            display: none !important;
        `}
          </style>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default JivoChatCustomization;
