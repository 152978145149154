import { useContext, useEffect, useState } from "react";
import AllChats from "./shared/AllChats";
import OpenChat from "./shared/OpenChat";
import { useSelector } from "react-redux";
import LoginModel from "pages/PayPerView/shared/LoginModal";
import { IUserChat } from "types/IUserChat";
import { ChatContext } from "context/ChatContext";
import NoConversationSelectedComponent from "./shared/NoConversationSelectedComponent";

function UserChatMain(props: IUserChat) {
  const { selectedChatMain, setSelectedChatMain } = useContext(ChatContext);

  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const [chatIsOpen, setChatIsOpen] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  useEffect(() => {
    if (!loggedInUser) {
      setShowLoginModal(true);
    }
  }, [loggedInUser]);

  return (
    <>
      {loggedInUser ? (
        <>
          <div className="w-full flex justify-between items-start gap-3 max-md:hidden">
            <div className="w-1/2">
              <AllChats setSelectedChat={setSelectedChatMain} />
            </div>
            <div className="w-screen bg-[var(--Color1E1E1E)] rounded-2xl">
              {!selectedChatMain ? (
                <NoConversationSelectedComponent />
              ) : (
                <OpenChat
                  sidePanel={false}
                  selectedChat={selectedChatMain}
                  setSelectedChat={setSelectedChatMain}
                />
              )}
            </div>
          </div>

          {/* <div className="md:pt-5 md:pb-5 ">
            <div className="flex w-full mx-auto h-[85vh] justify-between  gap-5 max-md:hidden ">
              <div className="w-[50%] ">
                <AllChats setSelectedChat={setSelectedChatMain} />
              </div>

              <div className="w-screen bg-[var(--Color1E1E1E)] rounded-2xl">
                {!selectedChatMain ? (
                  <NoConversationSelectedComponent />
                ) : (
                  <OpenChat
                    sidePanel={false}
                    selectedChat={selectedChatMain}
                    setSelectedChat={setSelectedChatMain}
                  />
                )}
              </div>
            </div>
          </div> */}

          {/* mobile view */}

          <div className="relative w-full mx-auto h-[85vh]  md:hidden">
            {chatIsOpen ? (
              <div
                className={`w-full z-10 ${
                  chatIsOpen ? `left-0` : `left-[-120%]`
                }  duration-300 ease-in-out`}
              >
                <OpenChat
                  sidePanel={false}
                  setChatIsOpen={setChatIsOpen}
                  selectedChat={selectedChatMain}
                  setSelectedChat={setSelectedChatMain}
                />
              </div>
            ) : (
              <div
                className={`w-full  ${
                  !chatIsOpen ? `right-0` : `right-[-120%]`
                }  duration-300 ease-in-out`}
              >
                <AllChats
                  setChatIsOpen={setChatIsOpen}
                  setSelectedChat={setSelectedChatMain}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {showLoginModal ? (
            <LoginModel
              showLoginModal={showLoginModal}
              // clickLogin={closeLoginModal}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default UserChatMain;
