import { RouteName } from "constant/routeNames";
import FeaturePages from "layout/FeaturePages";
import { Route, Routes } from "react-router-dom";
import AnotherUserProfile from "./userProfile/AnotherUserProfile";
import UserFollowers from "./userProfile/UserFollowers";
import UserFollowings from "./userProfile/UserFollowings";
import UserProfileHome from "./userProfile/UserProfileHome";

function UserProfile({
  handleClick,
  isActive,
  setIsActive,
  anyPlayerActive,
  //
  messages,
  chats,
  createNewChat,
  sendMessage,
  notifyTyping,
  showChatProgress,
  initialScroll,
  latestChat,
}: any) {
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <Routes>
        <Route
          path={RouteName.UserProfileHomeUserNameRoute}
          element={
            <FeaturePages
              Content={UserProfileHome}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={""}
              //
            />
          }
        />
        <Route
          path={RouteName.UserFollowersRoute}
          element={
            <FeaturePages
              Content={UserFollowers}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={""}
            />
          }
        />
        <Route
          path={RouteName.UserFollowingsRoute}
          element={
            <FeaturePages
              Content={UserFollowings}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={""}
            />
          }
        />
        <Route
          path={RouteName.UserProfileHomeRoute}
          element={
            <FeaturePages
              Content={AnotherUserProfile}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={""}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default UserProfile;
