import axios from "axios";
import authHeader from "utils/auth-header";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;

const generatePin = async () => {
  try {
    const response = await axios({
      method: "post",
      url: `${baseURL}/auth/generate-pin`,
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const ConnectTvService = { generatePin };

export default ConnectTvService;
