import { RouteName } from 'constant/routeNames';
import FeaturePages from 'layout/FeaturePages';
import React from 'react'
import { Route, Routes } from 'react-router-dom';
import ProfileHome from './newProfilePage/ProfileHome';

function ProfilePage({
    handleClick,
    isActive,
    setIsActive,
    anyPlayerActive,
  }: any) {
    return (
      <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
        <Routes>
          <Route
            path={RouteName.NewUserProfileHomeUserNameRoute}
            element={
              <FeaturePages
                Content={ProfileHome}
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                title={""}
              />
            }
          />
        </Routes>
      </div>
    );
  }

export default ProfilePage