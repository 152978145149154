import React from "react";
import "styles/connectTvLoading.css";

function LoadingPinSkeleton() {
  return (
    <>
      <div className="flex justify-center w-full mx-auto">
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
      </div>
    </>
  );
}

export default LoadingPinSkeleton;
