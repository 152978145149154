import React from "react";
import { FiTv } from "react-icons/fi";
import { useSelector } from "react-redux";

function ConnectTvBtn({ openConnectTvModel }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  return (
    <>
      {loggedInUser && (
        <button
          className={`flex items-center min-w-[128px] h-[40px] p-4 text-center text-white max-sm:p-2 `}
          onClick={openConnectTvModel}
        >
          <div className="flex items-center justify-end w-full gap-2 px-4 py-2 mx-auto border-white border-solid rounded-full border-[1px]">
            <p>Connect</p>
            {/* <MdOutlineQrCode2 className="text-3xl" /> */}
            <FiTv className="text-2xl" />
          </div>
        </button>
      )}
    </>
  );
}

export default ConnectTvBtn;
