import Ripple from "components/Ripple";
import { RouteName } from "constant/routeNames";
import { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CiLogout } from "react-icons/ci";
import { FiLogOut } from "react-icons/fi";
import { HiArrowLeft } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { removeAllPlayer } from "redux/AudioPlayerRedux";
import { setVideoSuccess } from "redux/VideoPlayerRedux";
import PPVideoPlayerRightSidePanel from "./videoPlayer/PPVideoPlayerRightSidePanel";
import PPVideoPlayerLeftSidePanel from "./videoPlayer/PPVideoPlayerLeftSidePanel";
import PayperviewService from "services/payperview/PayperviewServie";
import TicketVerificationModal from "components/TicketVerificationModal";
import PurchasedSection from "./videoPlayer/sections/PurchasedSection";
import EventWaitingSection from "./videoPlayer/sections/EventWaitingSection";
import GeoLocationPopup from "pages/videoPage/videoPlayer/shared/GeoLocationPopup";
import PPVVideoPlayer from "./videoPlayer/PPVVideoPlayer";
import PopUpModel from "components/PopUpModel";
import LeftSideSkeleton from "components/skeleton/LeftSideSkeleton";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";

function PayPerViewPlayer() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { slug } = useParams();
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const [videoDetails, setVideoDetails] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [openSideModal, setOpenSideModal] = useState(false);
  const [explore, setExplore] = useState(false);

  const [hasPurchased, setHasPurchased] = useState<boolean>(true);
  const [hasPurchasedMovie, setHasPurchasedMovie] = useState<boolean>(true);
  const [remainingDays, setRemainingDays] = useState<any>();
  const [moviePlay, setMoviePlay] = useState<boolean>(false);
  const [streamable, setStreamable] = useState<boolean>(true);
  const [hasTicketVerified, setHasTicketVerified] = useState<boolean>(true);
  const [showTicketVerificationModal, setShowTicketVerificationModal] =
    useState(false);

  const [openGeoLoactionModel, setOpenGeoLoactionModel] = useState(false);
  const { prevPage } = location.state || { prevPage: null };

  const [isNewChat, setIsNewChat] = useState<boolean>(false);

  //load data according to the id
  useEffect(() => {
    // console.log("PayPerViewPlayer");
    dispatch(removeAllPlayer());
    const loadData = async () => {
      setLoading(true);
      setTimeout(async () => {
        await PayperviewService.getEventByEventSlug(slug).then((res) => {
          setVideoDetails(res?.data);
          if (res?.data?.allowed_to_watch === false) {
            setOpenGeoLoactionModel(true);
          }
          if (res?.data?.category === "Boxing") {
            if (res?.data?.streaming_status === "streaming") {
              setStreamable(true);
            } else {
              setStreamable(false);
            }
          }

          const value = {
            ...res?.data,
            url: res?.data?.stream_url,
            poster: res?.data?.thumbnailImage,
          };
          dispatch(setVideoSuccess(value));
        });
        setLoading(false);
      }, 1000);
    };
    loadData();
  }, [slug]);

  useEffect(() => {
    if (videoDetails?.id) {
      hasEventPurchased(videoDetails?.id);
    }
  }, [videoDetails?.id]);

  useEffect(() => {
    if (videoDetails?.id) {
      hasEventPurchasedMovie(videoDetails?.id);
    }
  }, [videoDetails?.id]);

  const navigateBack = () => {
    if (!explore) {
      if (prevPage) {
        // window.history.replaceState(null, "", prevPage);
        // window.location.reload();
        navigate(RouteName.PayperviewDetailsRoute.replace(":slug", prevPage));
      } else {
        navigate(RouteName.PayPerView.replace("/*", ""));
      }
    }
  };

  const hasEventPurchased = async (event_id: any) => {
    const response = await PayperviewService.hasPurchasedEvent(event_id);
    // console.log("hasEventPurchased", response);
    setHasPurchased(response?.hasPurchased);
    setHasTicketVerified(response?.has_ticket_verified);
  };

  const hasEventPurchasedMovie = async (event_id: any) => {
    const response = await PayperviewService.hasPurchasedMovie(event_id);
    setHasPurchasedMovie(response?.isRentalValid);
    setRemainingDays(response?.remaining_days);

    if (hasPurchasedMovie === true && remainingDays !== 0) {
      setMoviePlay(true);
    } else {
      setMoviePlay(false);
    }
  };
  // console.log("purchased", hasPurchasedMovie);
  // console.log(moviePlay);

  useEffect(() => {
    if (videoDetails?.category === "Boxing") {
      if (hasPurchased && streamable && !hasTicketVerified) {
        setShowTicketVerificationModal(true);
      } else {
        setShowTicketVerificationModal(false);
      }
    } else {
      if (hasPurchasedMovie === false && remainingDays !== 0) {
        setShowTicketVerificationModal(true);
      } else {
        setShowTicketVerificationModal(false);
      }
    }
  }, [
    hasPurchasedMovie,
    remainingDays,
    hasPurchased,
    streamable,
    hasTicketVerified,
    videoDetails,
  ]);

  const closeTicketModal = () => {
    setShowTicketVerificationModal(false);
    navigate(RouteName.PayPerView.replace("*", ""));
  };

  // geo location function
  const closeModal = () => {
    setOpenGeoLoactionModel(false);
    navigate(RouteName.PayPerView.replace("*", ""));
  };

  const handleToggleModal = () => {
    setOpenSideModal((val) => !val);
    setIsNewChat(false);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  // console.log("loading", loading);

  return (
    <>
      {parseFloat(videoDetails?.price) === 0 ? (
        <div>
          <div>
            {" "}
            <div className="max-sm:hidden max-md:hidden">
              <PPVideoPlayerRightSidePanel
                isActiveRight={openSideModal}
                videoDetails={videoDetails}
                setIsNewChat={setIsNewChat}
                isNewChat={isNewChat}
              />
              <PPVideoPlayerLeftSidePanel
                isActive={explore}
                videoDetails={videoDetails}
              />
            </div>
            <div
              className={`fixed z-50 flex items-center justify-between w-full px-10 mt-0 max-sm:sticky`}
            >
              <div className="!z-10 flex items-center mt-10 ">
                <div
                  className={`!z-20 flex items-center justify-center w-10 h-9 text-2xl rounded-full max-sm:scale-[80%] max-sm:ml-[-20px] max-sm:mt-[-20px]  duration-700  ${
                    explore
                      ? "bg-gray-700 duration-500 text-gray-900"
                      : "bg-white duration-500 cursor-pointer"
                  }`}
                  onClick={navigateBack}
                >
                  <HiArrowLeft />
                </div>

                <div
                  className={`!z-50 flex items-center justify-center w-9 h-9 text-2xl ml-4  rounded-full cursor-pointer max-sm:hidden   ${
                    explore
                      ? "  bg-red-600 duration-500 text-white"
                      : " bg-white duration-500 cursor-pointer text-black"
                  }`}
                  onClick={() => setExplore((val) => !val)}
                >
                  <AiOutlineInfoCircle className="" />
                </div>
                <div
                  className={`pt-1.5 pl-10 text-base duration-500 font-bold  rounded-full w-44 h-9 -ml-9 bg-white  ${
                    explore
                      ? "w-[130px] block pl-10 duration-200  "
                      : "w-0 hidden duration-200 "
                  }`}
                >
                  Explore {pathname === ""}
                </div>
                {videoDetails?.isEnableChat === 0 ? (
                  <></>
                ) : (
                  <>
                    <div
                      className={`!z-50 absolute flex items-center justify-center right-10 w-10 h-10 text-2xl bg-white rounded-full cursor-pointer mt-0 max-sm:hidden max-md:hidden max-lg:hidden${
                        openSideModal
                          ? "mr-[400px] duration-500 z-30"
                          : "mr-5 duration-500"
                      }`}
                      onClick={handleToggleModal}
                    >
                      {openSideModal ? (
                        <FiLogOut />
                      ) : (
                        <div className="relative">
                          {isNewChat ? (
                            <>
                              <IoChatbubbleEllipsesOutline />
                              <span className="absolute top-[-2px] right-[-2px] w-3 h-3 bg-red-500 rounded-full"></span>
                            </>
                          ) : (
                            <IoChatbubbleEllipsesOutline />
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="w-[99.98%] mx-auto overflow-hidden md:h-screen sm:bg-black ">
              <div className="flex justify-center mx-auto overflow-hidden">
                {!loading && !openGeoLoactionModel ? (
                  <PPVVideoPlayer />
                ) : (
                  <div
                    className={`flex flex-col items-center justify-center  max-md:mt-10 md:h-[100vh] max-sm:h-[250px] max-md:h-[500px]`}
                  >
                    <Ripple />
                  </div>
                )}
                {/* {/ <VideoPlayer cdn="https://vz-6c38baf6-966.b-cdn.net/0d99cc81-3b20-411a-a0ea-46206df7aefa/playlist.m3u8" /> /} */}
              </div>
            </div>
            <div className="w-full z-100 sm:hidden  max-md:mt-[0px]">
              <div className="block sm:hidden">
                <div className=" max-sm:w-full">
                  <PPVideoPlayerLeftSidePanel
                    isActive={explore}
                    videoDetails={videoDetails}
                  />
                </div>

                {videoDetails?.isEnableChat === 0 ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <>
                      <div className="flex ">
                        <PPVideoPlayerRightSidePanel
                          isActiveRight={true}
                          videoDetails={videoDetails}
                          setIsNewChat={setIsNewChat}
                          isNewChat={isNewChat}
                        />
                      </div>
                    </>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* {/ geo location popup /} */}
          {openGeoLoactionModel && (
            <div className="z-50">
              <PopUpModel
                isOpen={openGeoLoactionModel}
                onClose={closeModal}
                width={"800px"}
                showCloseButton={false}
              >
                <GeoLocationPopup onClose={closeModal} />
              </PopUpModel>
            </div>
          )}
        </div>
      ) : (
        <div>
          {hasPurchased && (
            <>
              {streamable ? (
                <div>
                  {" "}
                  <div className="max-sm:hidden max-md:hidden">
                    <PPVideoPlayerRightSidePanel
                      isActiveRight={openSideModal}
                      videoDetails={videoDetails}
                      setIsNewChat={setIsNewChat}
                      isNewChat={isNewChat}
                    />

                    <div className="flex ">
                      <PPVideoPlayerLeftSidePanel
                        isActive={explore}
                        videoDetails={videoDetails}
                      />
                    </div>
                  </div>
                  <div
                    className={`fixed z-50 flex items-center justify-between w-full px-10 mt-0 max-sm:sticky`}
                  >
                    <div className="!z-10 flex items-center mt-10 ">
                      <div
                        className={`!z-20 flex items-center justify-center w-10 h-9 text-2xl rounded-full max-sm:scale-[80%] max-sm:ml-[-10px] max-sm:mt-[-20px] opacity-10 hover:opacity-100 duration-700 max-sm:opacity-100 ${
                          explore
                            ? "bg-gray-700 duration-500 text-gray-900"
                            : "bg-white duration-500 cursor-pointer"
                        }`}
                        onClick={navigateBack}
                      >
                        <HiArrowLeft />
                      </div>

                      <div
                        className={`!z-50 flex items-center justify-center w-9 h-9 text-2xl ml-4  rounded-full cursor-pointer max-sm:hidden  ${
                          explore
                            ? "  bg-red-600 duration-500 text-white"
                            : " bg-white duration-500 cursor-pointer text-black"
                        }`}
                        // onClick={handleToggleModal}
                        onClick={() => setExplore((val) => !val)}
                        // onClick={() => setOpenSideModal((val) => !val)}
                      >
                        <AiOutlineInfoCircle className="text-black" />
                      </div>
                      <div
                        className={`pt-1.5 pl-10 text-base duration-500 font-bold  rounded-full w-44 h-9 -ml-9 bg-white  ${
                          explore
                            ? "w-[130px] block pl-10 duration-200  "
                            : "w-0 hidden duration-200 "
                        }`}
                      >
                        Explore {pathname === ""}
                      </div>

                      {videoDetails?.isEnableChat === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div
                            className={`!z-50 absolute flex items-center justify-center right-10 w-10 h-10 text-2xl bg-white rounded-full cursor-pointer mt-0 max-sm:hidden max-md:hidden max-lg:hidden${
                              openSideModal
                                ? "mr-[400px] duration-500 z-30"
                                : "mr-5 duration-500"
                            }`}
                            onClick={() => setOpenSideModal((val) => !val)}
                          >
                            {openSideModal ? (
                              <FiLogOut />
                            ) : (
                              <div className="relative">
                                {isNewChat ? (
                                  <>
                                    <IoChatbubbleEllipsesOutline />
                                    <span className="absolute top-[-2px] right-[-2px] w-3 h-3 bg-red-500 rounded-full"></span>
                                  </>
                                ) : (
                                  <IoChatbubbleEllipsesOutline />
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="w-[99.98%] mx-auto overflow-hidden md:h-screen sm:bg-black">
                    {videoDetails?.category === "Movie" ? (
                      <>
                        <div className="flex justify-center mx-auto overflow-hidden">
                          {hasPurchasedMovie && !openGeoLoactionModel ? (
                            <PPVVideoPlayer />
                          ) : (
                            <div
                              className={`flex flex-col items-center justify-center  max-md:mt-10 md:h-[100vh] max-sm:h-[250px] max-md:h-[500px]`}
                            >
                              <Ripple />
                            </div>
                          )}
                          {/* {/ <VideoPlayer cdn="https://vz-6c38baf6-966.b-cdn.net/0d99cc81-3b20-411a-a0ea-46206df7aefa/playlist.m3u8" /> /} */}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex justify-center mx-auto overflow-hidden">
                          {!loading &&
                          hasPurchased &&
                          hasTicketVerified &&
                          !openGeoLoactionModel ? (
                            <PPVVideoPlayer />
                          ) : (
                            <div
                              className={`flex flex-col items-center justify-center  max-md:mt-10 md:h-[100vh] max-sm:h-[250px] max-md:h-[500px]`}
                            >
                              <Ripple />
                            </div>
                          )}
                          {/* {/ <VideoPlayer cdn="https://vz-6c38baf6-966.b-cdn.net/0d99cc81-3b20-411a-a0ea-46206df7aefa/playlist.m3u8" /> /} */}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="w-full z-100 sm:hidden max-md:mt-[0px]">
                    <div className="block sm:hidden">
                      <div className="max-sm:h-[350px] max-md:h-[300px] max-sm:w-full ">
                        {!loading ? (
                          <>
                            <PPVideoPlayerLeftSidePanel
                              isActive={explore}
                              videoDetails={videoDetails}
                            />
                          </>
                        ) : (
                          <>
                            <LeftSideSkeleton />
                          </>
                        )}
                      </div>

                      {videoDetails?.isEnableChat === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div className="flex ">
                            <PPVideoPlayerRightSidePanel
                              isActiveRight={true}
                              videoDetails={videoDetails}
                              setIsNewChat={setIsNewChat}
                              isNewChat={isNewChat}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <EventWaitingSection ppv_event={videoDetails} />
                </>
              )}
            </>
          )}
          {!hasPurchased && <PurchasedSection ppv_event={videoDetails} />}
          {/* {/ geo location popup /} */}
          {openGeoLoactionModel && (
            <div className="z-50">
              <PopUpModel
                isOpen={openGeoLoactionModel}
                onClose={closeModal}
                width={"800px"}
                showCloseButton={false}
              >
                <GeoLocationPopup onClose={closeModal} />
              </PopUpModel>
            </div>
          )}
        </div>
      )}

      <TicketVerificationModal
        isOpen={showTicketVerificationModal}
        onClose={closeTicketModal}
        ppv_event={videoDetails}
      />
    </>
  );
}

export default PayPerViewPlayer;
