import { AiFillInstagram } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { ProfileSocialMedia } from "constant/ProfileSocialMedia";
import { FaSoundcloud, FaInstagramSquare, FaYoutube, FaTwitter, FaFacebookF } from 'react-icons/fa';

// Helper function to ensure URL is absolute
const formatUrl = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

function SocialMediaBtn({ user }: any) {
  const [socialLinks, setSocialLinks] = useState<any[]>([]);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  useEffect(() => {
    // Filter out the social media links from the user object
    const links = ProfileSocialMedia.map((media) => {
      if (user[media.title]) {
        return {
          title: media.title,
          value: user[media.title]
        };
      }
      return null;
    }).filter(Boolean); // Remove null values

    setSocialLinks(links);
  }, [user]);

  return (
    <div className="flex justify-between gap-1 max-sm:p-0 mt-7">
      {socialLinks &&
        socialLinks?.map((link: any, i: number) => {
          // Find the matching social media object
          const socialMedia = ProfileSocialMedia.find(
            (media) => media.title === link.title
          );

          if (socialMedia) {
            const IconComponent = socialMedia.icon;
            return (
              <a
                href={formatUrl(link.value)} // Ensure the URL is absolute
                target="_blank"
                rel="noreferrer nofollow"
                key={i}
                className="text-xl text-[var(--red)]"
              >
                <IconComponent className="mr-4" />
              </a>
            );
          }

          return null;
        })}
    </div>
  );
}

export default SocialMediaBtn;
