import {
  BrowserRouter,
  Route,
  Routes,
  ScrollRestoration,
} from "react-router-dom";

import LandingPages from "layout/LandingPages";
import LiveTvLandingPage from "pages/seoLandingPage/LiveTvLandingPage";
import LiveTvPage from "pages/LiveTvPage";
import Register from "pages/Register";
import MusicPage from "pages/MusicPage";
import VideoPage from "pages/VideoPage";
import Welcome from "pages/Welcome";

import { RouteName } from "constant/routeNames";
import MusicSEOLandingPage from "pages/seoLandingPage/MusicSEOLandingPage";
import VideoSEOLandingPage from "pages/seoLandingPage/VideoSEOLandingPage";
import { useEffect, useState } from "react";
import RadioPage from "pages/RadioPage";
import PodcastPage from "pages/PodcastPage";
import EditProfile from "pages/EditProfile";
import BoosterPage from "pages/BoosterPage";
import UserProfile from "pages/UserProfile";
import Login from "pages/Login";
import VideoPlayerPage from "pages/videoPage/VideoPlayerPage";
// import AudioPlayer3 from "pages/musicPage/audioPlayer/AudioPlayer3/AudioPlayer3";
// import RadioPlayer from "pages/radioPage/RadioPlayer/RadioPlayer";
// import PodcastPalyer from "pages/podcastPage/PodcastPlayer/PodcastPalyer";
import { useSelector } from "react-redux";
import ForgotPassword from "pages/ForgotPassword";
import SearchPage from "pages/SearchPage";
import ResetPassword from "pages/ResetPassword";
import BecomeACreator from "pages/BecomeACreator";
import WaringPage from "pages/WaringPage";

import TermsAndConditions from "pages/TermsAndConditions";
import PrivacyPolicy from "pages/PrivacyPolicy";
import FeaturePages from "layout/FeaturePages";
import LegalInformation from "pages/LegalInformation";
import AboutUs from "pages/AboutUs";
import CookiesAndPersonalData from "pages/CookiesAndPersonalData";
// import VideoCreator from "pages/BecomeACreator/VideoCreator";
// import VideoDetails from "pages/videoPage/VideoDetails";
// import PayPerView from "pages/PayPerView";
// import Library from "pages/PlaylistPage";
import AppPage from "pages/ConditionPage/AppPage";
// import PopUpModel from "components/PopUpModel";
// import ChangePasswordOldUser from "components/shared/ChangePasswordOldUser";
import UpdateOldUserPassword from "pages/UpdateOldUserPassword";
import OldUserRedirect from "components/OldUserRedirect";
import VideoCreatorPlaylist from "pages/BecomeACreator/VideoCreatorPlaylist";
import DMCA from "pages/DMCA";
import RedirectComponent from "components/RedirectComponent";
import PlaylistPage from "pages/PlaylistPage";
// import NewRadioPlayer from "pages/radioPage/NewRadioPlayer/NewRadioPlayer";
// import TestPlayer from "./../pages/musicPage/NewAudioPlayer/TestPlayer";
import StarPage from "pages/StarPage";
import CreatorPage from "pages/CreatorPage";
import VideoTVSeriesPlayerPage from "pages/videoPage/VideoTVSeriesPlayerPage";
import TransectionPage from "pages/TransectionPage";
import AudioPlayer from "pages/musicPage/audioPlayer/AudioPlayer";
import PodcastPlayer from "pages/podcastPage/PodcastPlayer/NewPodcastPlayer/PodcastPlayer";
import RadioPlayer from "pages/radioPage/RadioPlayer/NewRadioPlayer/RadioPlayer";
import PayPerView from "pages/PayPerView";
import GeneralServices from "services/General/GeneralServices";
import JivoChatCustomization from "components/JivoChatCustomization";
import DisableRightClick from "components/DisableRightClick";
import BlogPage from "pages/BlogPage";
import PPVLanding from "pages/PayPerView/PPVLanding";
import UserChat from "pages/UserChat";
import UserChatMain from "pages/UserChat/UserChatMain";
import { useUserChatWebSocket } from "hooks/useUserChatWebSocket";
import ProfilePage from "pages/ProfilePage";
import ConnectTv from "pages/ConnectTv/ConnectTv";

const AppRouter = () => {
  const {
    playlist,
    currentAudio,
    playerVisible,
    currentRadio,
    radioVisible,
    currentPodcast,
    podcastVisible,
  } = useSelector((state: any) => state?.audio);

  const [isActive, setIsActive] = useState<boolean>(true);
  const [enabled, setEnabled] = useState(() => {
    const storedValue = localStorage.getItem("ppv_enabled");
    return storedValue !== null ? storedValue : "";
  });
  const [blogEnabled, setBlogEnable] = useState(() => {
    const storedblogValue = localStorage.getItem("blog_enabled");
    return storedblogValue !== null ? storedblogValue : "";
  });

  useEffect(() => {
    GeneralServices.getAllConfigData().then((res) => {
      // console.log(res);
      // const enabledValue = res?.data[18]?.value;
      // setEnabled(enabledValue);
      // localStorage.setItem("ppv_enabled", enabledValue);

      const enabledValue = res.data.find(
        (item: any) => item.title === "ppv_production_enabled"
      )?.value;
      setEnabled(enabledValue);
      localStorage.setItem("ppv_enabled", enabledValue);

      const enalbeBlog = res.data.find(
        (item: any) => item.title === "blog_enabled"
      )?.value;
      setBlogEnable(enalbeBlog);
      localStorage.setItem("blog_enabled", enalbeBlog);
    });
  }, []);

  // const [isUpdatedPassword, setIsUpdatedPassword] = useState(true);
  // const [showModal, setShowModal] = useState(true);
  // const openModal = () => {
  //   setShowModal(true);
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  // };
  // const [isMusic, setIsMusic] = useState(true);
  //toggle side bar
  const handleClick = () => {
    // e.preventDefault();
    setIsActive(!isActive);
  };

  // useEffect(() => {
  //   console.log(isActive);
  // }, [isActive]);

  // audio player visibility
  // const [playerIsActive, setPlayerIsActive] = useState<boolean>(isActive);
  // useEffect(() => {
  //   setPlayerIsActive(isActive);
  // }, [isActive]);

  const [anyPlayerActive, setAnyPlayerActive] = useState<boolean>(false);
  useEffect(() => {
    if (
      (playerVisible && currentAudio) ||
      (radioVisible && currentRadio) ||
      (podcastVisible && currentPodcast)
    ) {
      setAnyPlayerActive(true);
    } else {
      setAnyPlayerActive(false);
    }
  }, [
    playerVisible,
    radioVisible,
    podcastVisible,
    currentRadio,
    currentAudio,
    currentPodcast,
  ]);

  return (
    <>
      <BrowserRouter>
        {/* Include the OldUserRedirect component */}
        <JivoChatCustomization />
        <OldUserRedirect />
        <RedirectComponent />
        {/* <DisableRightClick /> */}
        <Routes>
          {/* <Route
            path={RouteName.Home}
            element={
              <>
                <Home setPlayerIsActive={setPlayerIsActive} />
              </>
            }
          /> */}

          <Route
            path={RouteName.Home}
            element={
              <>
                <Welcome
                  handleClick={handleClick}
                  isActive={isActive}
                  // setPlayerIsActive={setPlayerIsActive}
                  anyPlayerActive={anyPlayerActive}
                  setIsActive={setIsActive}
                />
              </>
            }
          />
          <Route
            path={RouteName.EditProfile}
            element={
              <FeaturePages
                Content={EditProfile}
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                title={"Settings"}
              />
            }
          />
          <Route
            path={RouteName.Booster}
            element={
              <BoosterPage
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                anyPlayerActive={anyPlayerActive}
              />
            }
          />
          <Route
            path={RouteName.UserProfile}
            element={
              <UserProfile
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                anyPlayerActive={anyPlayerActive}
                //
              />
            }
          />
          <Route
            path={RouteName.NewProfile}
            element={
              <ProfilePage
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                anyPlayerActive={anyPlayerActive}
              />
            }
          />
          <Route
            path={RouteName.BecomeACreator}
            element={
              <BecomeACreator
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                anyPlayerActive={anyPlayerActive}
              />
            }
          />
          {/* <Route
            path={RouteName.Apps}
            element={
              <ConditionPage
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                anyPlayerActive={anyPlayerActive}
              />
            }
          /> */}
          <Route
            path={RouteName.Video}
            element={
              <VideoPage
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                // setPlayerIsActive={setPlayerIsActive}
                anyPlayerActive={anyPlayerActive}
              />
            }
          />
          <Route path={RouteName.WatchMovies} element={<VideoPlayerPage />} />
          <Route
            path={RouteName.WatchTVSeries}
            element={<VideoTVSeriesPlayerPage />}
          />
          <Route
            path={RouteName.Music}
            element={
              <MusicPage
                handleClick={handleClick}
                isActive={isActive}
                // isMusic={isMusic}
                setIsActive={setIsActive}
                // setPlayerIsActive={setPlayerIsActive}
                anyPlayerActive={anyPlayerActive}
              />
            }
          />
          <Route
            path={RouteName.LiveTV}
            element={
              <LiveTvPage
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                // setPlayerIsActive={setPlayerIsActive}
                anyPlayerActive={anyPlayerActive}
              />
            }
          />
          <Route
            path={RouteName.Radio}
            element={
              <RadioPage
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                // setPlayerIsActive={setPlayerIsActive}
                anyPlayerActive={anyPlayerActive}
              />
            }
          />
          <Route
            path={RouteName.Podcast}
            element={
              <PodcastPage
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                // setPlayerIsActive={setPlayerIsActive}
                anyPlayerActive={anyPlayerActive}
              />
            }
          />
          <Route
            path={RouteName.UserChat}
            element={
              <UserChat
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                // setPlayerIsActive={setPlayerIsActive}
                anyPlayerActive={anyPlayerActive}
                //
              />
              // <FeaturePages
              //   Content={UserChatMain }
              //   handleClick={handleClick}
              //   isActive={isActive}
              //   setIsActive={setIsActive}
              //   title={""}
              // />
              // <>
              //   <UserChat
              //     handleClick={handleClick}
              //     isActive={isActive}
              //     // setPlayerIsActive={setPlayerIsActive}
              //     anyPlayerActive={anyPlayerActive}
              //     setIsActive={setIsActive}
              //   />
              // </>
            }
          />
          <Route
            path={RouteName.VideoCreator}
            element={
              <>
                <CreatorPage
                  handleClick={handleClick}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  // setPlayerIsActive={setPlayerIsActive}
                  anyPlayerActive={anyPlayerActive}
                />
              </>
            }
          />
          <Route
            path={RouteName.CreatorPlaylist}
            element={
              <>
                <VideoCreatorPlaylist
                  handleClick={handleClick}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  // setPlayerIsActive={setPlayerIsActive}
                  anyPlayerActive={anyPlayerActive}
                />
              </>
            }
          />
          {blogEnabled === "1" ? (
            <Route
              path={RouteName.Blog}
              element={
                <BlogPage
                  handleClick={handleClick}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  // setPlayerIsActive={setPlayerIsActive}
                  anyPlayerActive={anyPlayerActive}
                />
              }
            />
          ) : null}
          {/* PayPerView */}
          {enabled === "1" ? (
            <Route
              path={RouteName.PayPerView}
              element={
                <PayPerView
                  handleClick={handleClick}
                  isActive={isActive}
                  setIsActive={setIsActive}
                />
              }
            />
          ) : null}

          <Route
            path={RouteName.Search}
            element={
              <SearchPage
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                anyPlayerActive={anyPlayerActive}
              />
            }
          />
          {/* star page */}
          <Route
            path={RouteName.StarPage}
            element={
              <StarPage
                handleClick={handleClick}
                isActive={isActive}
                // isMusic={isMusic}
                setIsActive={setIsActive}
                // setPlayerIsActive={setPlayerIsActive}
                anyPlayerActive={anyPlayerActive}
              />
            }
          />
          {/* SEO pages */}
          <Route
            path={RouteName.LiveTVSEOLandingPage}
            element={
              <>
                <LandingPages
                  Content={LiveTvLandingPage}
                  anyPlayerActive={anyPlayerActive}
                />
              </>
            }
          />
          <Route
            path={RouteName.MusicSEOLandingPage}
            element={
              <>
                <LandingPages
                  Content={MusicSEOLandingPage}
                  anyPlayerActive={anyPlayerActive}
                />
              </>
            }
          />
          <Route
            path={RouteName.VideoSEOLandingPage}
            element={
              <>
                <LandingPages
                  Content={VideoSEOLandingPage}
                  anyPlayerActive={anyPlayerActive}
                />
              </>
            }
          />
          <Route
            path={RouteName.Apps}
            element={
              <>
                <AppPage
                  handleClick={handleClick}
                  isActive={isActive}
                  setIsActive={setIsActive}
                />
              </>
            }
          />

          <Route path={RouteName.Register} element={<Register />} />
          <Route path={RouteName.Login} element={<Login />} />
          <Route
            path={RouteName.UpdateOldUserPassword}
            element={<UpdateOldUserPassword />}
          />
          <Route path={RouteName.ForgotPassword} element={<ForgotPassword />} />
          <Route path={RouteName.ResetPassword} element={<ResetPassword />} />

          <Route
            path={RouteName.PPvLandingPage}
            element={
              <>
                <PPVLanding
                  handleClick={handleClick}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  // setPlayerIsActive={setPlayerIsActive}
                  anyPlayerActive={anyPlayerActive}
                />
              </>
            }
          />
          <Route
            path={RouteName.TermsAndConditions}
            element={
              <>
                <TermsAndConditions
                  handleClick={handleClick}
                  isActive={isActive}
                  // setPlayerIsActive={setPlayerIsActive}
                  anyPlayerActive={anyPlayerActive}
                />
              </>
            }
          />
          <Route
            path={RouteName.CookiesAndPersonalData}
            element={
              <>
                <CookiesAndPersonalData
                  handleClick={handleClick}
                  isActive={isActive}
                  // setPlayerIsActive={setPlayerIsActive}
                  anyPlayerActive={anyPlayerActive}
                />
              </>
            }
          />
          <Route
            path={RouteName.PrivacyPolicy}
            element={
              <>
                <PrivacyPolicy
                  handleClick={handleClick}
                  isActive={isActive}
                  // setPlayerIsActive={setPlayerIsActive}
                  anyPlayerActive={anyPlayerActive}
                />
              </>
            }
          />
          <Route
            path={RouteName.LegalInformation}
            element={
              <>
                <LegalInformation
                  handleClick={handleClick}
                  isActive={isActive}
                  // setPlayerIsActive={setPlayerIsActive}
                  anyPlayerActive={anyPlayerActive}
                />
              </>
            }
          />
          <Route
            path={RouteName.AboutUs}
            element={
              <>
                <AboutUs
                  handleClick={handleClick}
                  isActive={isActive}
                  // setPlayerIsActive={setPlayerIsActive}
                  anyPlayerActive={anyPlayerActive}
                />
              </>
            }
          />
          <Route
            path={RouteName.DMCA}
            element={
              <DMCA
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
              />
            }
          />
          {enabled === "1" && (
            <Route
              path={RouteName.transection}
              element={
                <TransectionPage
                  handleClick={handleClick}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  anyPlayerActive={anyPlayerActive}
                />
              }
            />
          )}
          <Route
            path={RouteName.CookiesAndPersonalData}
            element={
              <>
                <CookiesAndPersonalData
                  handleClick={handleClick}
                  isActive={isActive}
                  // setPlayerIsActive={setPlayerIsActive}
                  anyPlayerActive={anyPlayerActive}
                />
              </>
            }
          />
          <Route
            path={RouteName.Playlists}
            element={
              <FeaturePages
                Content={PlaylistPage}
                handleClick={handleClick}
                isActive={isActive}
                setIsActive={setIsActive}
                title={""}
              />
            }
          />

          {/* <Route path={RouteName.BecomeACreator} element={<BecomeACreator />} /> */}
          <Route
            path={RouteName.TVSeriesVideoPage}
            element={
              <>
                <VideoPlayerPage />
              </>
            }
          />
          <Route path="*" element={<WaringPage />} />

          {/* connect tv */}
          <Route
            path={RouteName.ConnectTv}
            element={
              <>
                <ConnectTv />
              </>
            }
          />
          {/* <Route path={RouteName.pageNotFound} element={<WaringPage />} /> */}
        </Routes>

        {/* audio player */}
        {playerVisible && playlist && (
          <div>
            {/* <AudioPlayer3 isActive={isActive} /> */}
            <AudioPlayer isActive={isActive} />
            {/* <AudioPlayer isActive={isActive} /> */}
            {/* <TestPlayer isActive={isActive} /> */}
          </div>
        )}

        {/* radio */}
        {radioVisible && currentRadio && (
          <div>
            <RadioPlayer isActive={isActive} />
            {/* <RadioPlayer /> */}
            {/* <NewRadioPlayer /> */}
          </div>
        )}

        {/* podcast */}
        {podcastVisible && currentPodcast && (
          <div>
            {/* <PodcastPalyer isActive={isActive} /> */}
            {/* common player */}
            <PodcastPlayer isActive={isActive} />
          </div>
        )}
      </BrowserRouter>

      {/* {isUpdatedPassword === false ? (
        <div className="z-50">
          <PopUpModel
            isOpen={showModal}
            onClose={closeModal}
            width={"800px"}
            isUpdatedPassword={isUpdatedPassword}
          >
            <div>
              <ChangePasswordOldUser
                isUpdatedPassword={isUpdatedPassword}
                setIsUpdatedPassword={setIsUpdatedPassword}
              />
            </div>
          </PopUpModel>
        </div>
      ) : (
        <></>
      )} */}
    </>
  );
};

export default AppRouter;
