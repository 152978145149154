import { useEffect, useState } from "react";
import MusicService from "services/music/MusicService";
import { MusicCard } from "./shared/MusicCard";
import BackButton from "components/shared/BackButton";
import { useSelector } from "react-redux";
import LikeService from "services/music/LikeService";
import NewReleaseSeeAllSkeleton from "components/skeleton/NewReleaseSeeAllSkeleton";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Helmet } from "react-helmet-async";
import { Adsense } from "@ctrl/react-adsense";
import GeneralServices from "services/General/GeneralServices";
import MusicSeeAllSkeleton from "components/skeleton/MusicSeeAllSkeleton";

function SeeAllNewRelease() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [songs, setSongs] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(false);
  const navigate = useNavigate();
  const [gAdSlot, setgAdSlot] = useState<any>();

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_music_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    MusicService.getLatestMusic()
      .then((res: any) => {
        // console.log(res.latestSongs);
        setIsLoading(false);
        setSongs(res.latestSongs);
      })
      .catch((e) => {
        // console.log(e);
      });
  }, []);

  // like function
  const [favorite, setFavorite] = useState<any>();

  useEffect(() => {
    if (loggedInUser) {
      LikeService.getFavoriteById(loggedInUser?.id, loggedInUser?.id)
        .then((res) => {
          const favoritesIds = res?.profile?.loved?.data?.map(
            (item: any) => item?.id
          );
          setFavorite(favoritesIds);
        })
        .catch(() => {});
    }
  }, [loggedInUser?.id]);

  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  return (
    <>
      <Helmet>
        <title>New Releases - AYOZAT Music</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <link rel="canonical" href={`${FE_URL}/music/new-release`} />
        <meta property="og:title" content=" New Releases - AYOZAT Music" />
        <meta
          property="og:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:url" content={`${FE_URL}/music/new-release`} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content=" New Releases - AYOZAT Music" />
        <meta
          name="twitter:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT" />
        {/* <meta property="twitter:url" content={`${FE_URL}/music`}/> */}
      </Helmet>
      {!isLoading && (
        <>
          {/* main topic section */}

          <button
            className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px]"
            onClick={() => {
              navigate(-1);
            }}
          >
            <AiOutlineArrowLeft className="ml-3" />
          </button>

          <div className="mb-8">
            <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
              New Releases
            </h1>
          </div>
          {gAdSlot && (
            <Adsense
              client="ca-pub-5773929890067376"
              slot={gAdSlot}
              style={{
                display: "flex",
                marginBottom: "30px",
                marginTop: "20px",
                height: "90px",
                justifyContent: "center",
              }}
              format="horizontal"
              data-full-width-responsive="true"
              // format="fluid"
            />
          )}
          {songs && (
            <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 max-sm:w-full">
              {songs?.map((song: any, index: any) => (
                <div key={index} className="mb-5 mr-4">
                  <MusicCard
                    song={song}
                    favorite={favorite}
                    user={loggedInUser}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {isLoading && <MusicSeeAllSkeleton />}
    </>
  );
}

export default SeeAllNewRelease;
