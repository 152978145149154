import { AssetTypes } from "constant/AssetTypes";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import videojs from "video.js";
import { simpleDecryption } from "constant/SimpleDecryption";

// add viewport height and width
const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  width: 1920,
  height: 1080,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
};

// var CryptoJS = require("crypto-js");

export interface VideoLastWatch {
  currentTime: number;
  id: number;
  type: AssetTypes.Movie | AssetTypes.TVSeries;
  slug: string;
  title: string;
  duration: number;
  thumbnail: string;
  tv_show_name?: string;
  season_name?: string;
  stream?: string;
}

const PPVVideoPlayer = ({ ppvReplay = false }: any) => {
  const videoNode = useRef<HTMLVideoElement | null>(null);
  const player = useRef<videojs.Player>();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const iframeURL = process.env.REACT_APP_VIDEOPLAYER_IFRAME_URL;
  const BASE_URL = process.env.REACT_APP_PLAYERHOST;
  const { currentVideo, isFetching } = useSelector(
    (state: any) => state?.video
  );
  // console.log(currentVideo);
  const [lastWatchTime, setLastWatchTime] = useState<number>(0);
  const [duration, setDuration] = useState<any>(0);

  const [finalizedUrl, setFinalizedUrl] = useState<string | null>(null);

  const [dimensions, setDimensions] = useState({
    width: "100%",
    height: "100%",
  });

  const volume = useSelector((state: any) => state?.video?.volume);

  useEffect(() => {
    resizeIframe();
  }, []);

  // console.log("currentVideo",currentVideo)

  useEffect(() => {
    setTimeout(() => {
      const videoJsOptions = {
        sources: [
          {
            controls: true,
            responsive: true,
            fluid: true,
            src: `${finalizedUrl || currentVideo?.url}`,
            currentSrc: `${finalizedUrl || currentVideo?.url}`,
            buffered: [0.0, 0.04],
            // type: 'video/mp4',
          },
        ],
      };
      player.current?.dispose();
      if (videoNode.current) {
        player.current = videojs(videoNode.current, {
          ...initialOptions,
          ...videoJsOptions,
        }).ready(function () {
          videoNode.current?.play();
        });
      }
      return () => {
        if (player.current) {
          player.current.dispose();
        }
      };
    }, 3000);
  }, [currentVideo, finalizedUrl]);

  // console.log("playercurrentvideo",currentVideo);
  const handleMessage = (event: any) => {
    if (event.origin === BASE_URL) {
      // if (event.origin === 'http://127.0.0.1:5500') {
      // console.log(event);
      const receivedData = event.data;
      if (typeof receivedData === "number") {
        // console.log(Math.floor(receivedData));

        setLastWatchTime(Math.floor(receivedData));
        // console.log("trigger nextt", Math.floor(receivedData) / duration);
      }
      if (event.data.type === "duration") {
        // console.log('duration', event.data.time);
        setDuration(event.data.time);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const resizeIframe = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      const aspectRatio = 16 / 9; // Assuming 16:9 aspect ratio
      const maxWidth = window.innerWidth;
      const maxHeight = maxWidth / aspectRatio;

      // Set dimensions while maintaining aspect ratio
      let newWidth, newHeight;
      if (maxHeight > window.innerHeight) {
        newHeight = window.innerHeight;
        newWidth = newHeight * aspectRatio;
      } else {
        newWidth = maxWidth;
        newHeight = newWidth / aspectRatio;
      }

      setDimensions({
        width: `${newWidth}px`,
        height: `${newHeight}px`,
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      resizeIframe();
    };

    resizeIframe();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Decryption
  useEffect(() => {
    const encryptedStreamUrl = currentVideo?.url;
    if (encryptedStreamUrl) {
      const decryptedUrl = simpleDecryption(encryptedStreamUrl);
      setFinalizedUrl(decryptedUrl);
    }
  }, [currentVideo?.url, currentVideo?.host_name, finalizedUrl]);

  // console.log("BackEnd URL :" + currentVideo?.url);
  // console.log("Finalized URL :" + finalizedUrl);

  return (
    <div className="flex justify-center w-full mx-auto max-sm:w-full lg:h-max rounded-xl">
      {/* <video
        ref={videoNode}
        poster={currentVideo?.thumbnail_url}
        id="my-player"
        className="video-js vjs-luxmty vjs-16-9"
        src="http://127.0.0.1:5500/iframe/VideoPlayer.html"
      /> */}

      {!isFetching && (
        <iframe
          title={`Ayozat Video Player`}
          ref={iframeRef}
          width={dimensions.width}
          height={dimensions.height}
          // src={'http://127.0.0.1:5500/iframe/VideoPlayer.html'}
          src={iframeURL}
          onLoad={() => {
            const data = {
              source: "my-video",
              // src: "https://cdn.rtmp1.vodhosting.com/hls/Greyhound_Derby.m3u8",
              src: ppvReplay ? currentVideo?.url : finalizedUrl,
              userCurrentTime: currentVideo?.userCurrentTime,
              poster: currentVideo?.bannerImage
                ? currentVideo?.bannerImage
                : currentVideo?.poster,
              vastEnabled: true,
              vastTagPreroll: currentVideo?.vast_tag_url,
              pre_ad: currentVideo?.pre_ad,
              vastDuration: currentVideo?.duration
                ? currentVideo?.duration
                : 10,
            };
            const iframeWindow = iframeRef.current?.contentWindow;
            iframeWindow?.postMessage(JSON.parse(JSON.stringify(data)), "*");
          }}
          allow="autoplay; clipboard-write; encrypted-media; fullscreen"
          loading="lazy"
        ></iframe>
      )}
    </div>
  );
};

export default PPVVideoPlayer;
