import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserChatState {
  openChatDrawer: boolean;
  allChats: any[];
}

const initialState: UserChatState = {
  openChatDrawer: false,
  allChats: [],
};

const UserChatRedux = createSlice({
  name: "userChat",
  initialState,
  reducers: {
    toggleChatDrawer: (state, action: PayloadAction<boolean>) => {
      state.openChatDrawer = action.payload;
    },
    initilaizeChats: (state, action: PayloadAction<any[]>) => {
      state.allChats = [];
      const sortedChats = action.payload?.sort((a: any, b: any) => {
        const dateA = new Date(a.latest_message?.updated_at || 0);
        const dateB = new Date(b.latest_message?.updated_at || 0);
        return dateB.getTime() - dateA.getTime();
      });
      state.allChats = sortedChats;
    },
    updateChats: (state, action: PayloadAction<any>) => {
      const updatedChat = action.payload;
      // Find the index of the chat by id
      const chatIndex = state.allChats.findIndex(
        (chat) => chat.id === updatedChat.id
      );
      if (chatIndex !== -1) {
        state.allChats[chatIndex] = {
          ...state.allChats[chatIndex],
          ...updatedChat,
        };
      }
      state.allChats = Array.from(
        new Map(state.allChats.map((chat) => [chat.id, chat])).values()
      );
    },
    addNewChat: (state, action: PayloadAction<any>) => {
      const newChat = action.payload;

      // Add the new chat to the beginning of the array
      const updatedChats = [newChat, ...state.allChats];

      // Remove duplicates based on chat id
      state.allChats = Array.from(
        new Map(updatedChats.map((chat) => [chat.id, chat])).values()
      );
    },

    updateChatStatus: (state, action: PayloadAction<any>) => {
      const { chatId, status } = action.payload;

      // Map through the array and update the chat that matches the given id immutably
      state.allChats = state.allChats
      .map((chat) =>
        chat.id === chatId ? { ...chat, status: status, updated_at: new Date().toISOString() } : chat
      )
      .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
    },
  },
});

export const {
  toggleChatDrawer,
  initilaizeChats,
  addNewChat,
  updateChats,
  updateChatStatus,
} = UserChatRedux.actions;
export default UserChatRedux.reducer;
