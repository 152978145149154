import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import TvChannelSeeAllSkeleton from "components/skeleton/TvChannelSeeAllSkeleton";
import CreatorCard from "pages/videoPage/shared/CreatorCard";
import CreatorService from "services/creators/CreatorService";

function SeeAllCreators() {
  const [allCreators, setAllCreators] = useState<any>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    CreatorService.getNewAllCreators()
      .then((res) => {
        setAllCreators(res.data);
        setIsLoading(false);
      })
      .catch((e: any) => {
        setIsLoading(false);
        console.log(e);
      });
  }, []);

  return (
    <>
      {!isLoading && (
        <div>
          {/* main topic section */}
          <div>
            <button
              className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px]"
              onClick={() => {
                navigate(-1);
              }}
            >
              <AiOutlineArrowLeft className="ml-3" />
            </button>
          </div>
          <div className="mb-8">
            <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
              Creators
            </h1>
          </div>

          {allCreators && (
            <div className="grid w-full grid-cols-2 gap-4 mt-4 mb-8 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full">
              {allCreators?.map((creator: any, i: number) => (
                <div key={i}>
                  <CreatorCard creator={creator} />
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {isLoading && <TvChannelSeeAllSkeleton />}
    </>
  );
}

export default SeeAllCreators;
