import SubText from "components/shared/SubText";
import PayPerViewCard from "pages/PayPerView/shared/PayPerViewCard";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PayperviewService from "services/payperview/PayperviewServie";

function CreatorHomePayperviewGrid({ ppvEvents, setIsSelected, onClick }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [purchased, setPurchased] = useState<any>([]);

  useEffect(() => {
    PayperviewService.getPurchasedEvents(loggedInUser?.id)
      .then((res) => {
        setPurchased(res.data);
      })
      .catch((e: any) => {});
  }, []);

  const handleSelectSeeAll = () => {
    setIsSelected(8);
    if (onClick) onClick();
  };

  return (
    <div>
      {ppvEvents?.length !== 0 && (
        <>
          <div className="flex justify-between mt-10 ml-3 max-sm:ml-0">
            <SubText text="Pay-Per-View" className="font-bold" />
            {ppvEvents?.length > 6 && (
              <p
                onClick={handleSelectSeeAll}
                className="font-[500] text-[15px] text-[#F50537] cursor-pointer mr-10 max-sm:mr-3"
              >
                See all
              </p>
            )}
          </div>

          <div>
            <div className="mt-4 ml-3 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
              {ppvEvents?.length > 0 &&
                ppvEvents?.slice(0, 6)?.map((event: any, i: any) => (
                  <div key={i} className="max-sm:ml-[-15px]">
                    <PayPerViewCard
                      event={event}
                      user={loggedInUser}
                      isPurchased={
                        purchased.some(
                          (payperviewEvent: any) =>
                            payperviewEvent?.id === event?.id
                        )
                          ? true
                          : false
                      }
                    />
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CreatorHomePayperviewGrid;
