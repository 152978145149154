import React from "react";
import VideoCardGrid from "./shared/VideoCardGrid";
import SubText from "components/shared/SubText";
import PlaylistCoursel from "./shared/PlaylistCoursel";
import Movies from "./shared/Movies";
import Series from "./shared/Series";
import CreatorsMovies from "./shared/CreatorsMovies";
import CreatorsSeries from "./shared/CreatorsSeries";

function VideosPage({ creatorData, slug, isLoading }: any) {
  // console.log("creatorData", creatorData);
  return (
    <div>
      {creatorData?.videos?.length !== 0 && (
        <div className="mt-10">
          <VideoCardGrid
            creatorData={creatorData}
            slug={slug}
            isLoading={isLoading}
          />
        </div>
      )}
      {creatorData?.movies?.data.length !== 0 && (
        <div className="mt-5">
          <div className="flex justify-between mt-10 ml-3">
            <SubText text="Related Movies" className="font-bold" />
          </div>
          <CreatorsMovies creatorData={creatorData} />
        </div>
      )}

      {creatorData?.series?.data?.length !== 0 && (
        <div className="mt-5">
          <div className="flex justify-between mt-10 ml-3">
            <SubText text="Related Series" className="font-bold" />
          </div>
          <CreatorsSeries creatorData={creatorData} />
        </div>
      )}

      {creatorData?.playlists?.length !== 0 && (
        <div className="mt-5">
          <div className="flex justify-between mt-10 ml-3">
            <SubText text="Playlists" className="font-bold" />
          </div>
          <PlaylistCoursel creatorData={creatorData} />
        </div>
      )}

      {(creatorData?.acted_movies?.length > 0 ||
        creatorData?.produced_movies?.length > 0 ||
        creatorData?.directed_movies?.length > 0) && (
        <div className="mt-5">
          <div className="flex justify-between mt-10 ml-3">
            <SubText
              text={`Movies by ${creatorData?.general_info?.name}`}
              className="font-bold"
            />
          </div>
          <Movies creatorData={creatorData} />
        </div>
      )}

      {(creatorData?.acted_tv_shows?.length > 0 ||
        creatorData?.produced_tv_shows?.length > 0 ||
        creatorData?.directed_tv_shows?.length > 0) && (
        <div className="mt-5">
          <div className="flex justify-between mt-10 ml-3">
            <SubText
              text={`TV Series by ${creatorData?.general_info?.name}`}
              className="font-bold"
            />
          </div>
          <Series creatorData={creatorData} />
        </div>
      )}
    </div>
  );
}

export default VideosPage;
