function UserPageNavButton({
  pages,
  isSelected,
  setIsSelected,
  selected,
  setComponents,
  onClick,
}: any) {
  const handleSelect = () => {
    if (isSelected === pages.id) {
      setIsSelected();
    } else {
      setIsSelected(pages.id);
    }
    selectComponent();

    if (onClick) {
      onClick();
    }
  };

  const selectComponent = () => {
    setComponents(pages);
  };

  return (
    <div onClick={handleSelect}>
      {selected === true ? (
        <>
          {pages?.name === "Paid Subscription" ? (
            <>
              <button
                className={`flex justify-center items-center min-w-[120px] max-sm:min-w-[100px] h-[40px] p-3 text-center border-solid border border-white text-black rounded-full max-sm:p-2 max-sm:ml-0 button-green my-1`}
              >
                {pages.name}
              </button>
            </>
          ) : (
            <>
              <button
                className={`flex justify-center items-center min-w-[120px] max-sm:min-w-[100px] h-[40px] p-3 text-center  text-white rounded-full bg-[#a8022e] max-sm:p-2 border-[1px] max-sm:ml-0  my-1 hover:border-[#f30543] border-[#f30543]`}
              >
                {pages.name}
              </button>
            </>
          )}
        </>
      ) : (
        <button
          className={`flex justify-center items-center min-w-[120px] h-[40px] max-sm:min-w-[100px] p-3 text-center text-white max-sm:p-2 max-sm:ml-0 my-1 rounded-full border-[1px] hover:border-[#f30543] hover:text-[#f30543]`}
        >
          {pages.name}
        </button>
      )}
    </div>
  );
}

export default UserPageNavButton;
