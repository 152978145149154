import React from "react";
import { FaAngleDown } from "react-icons/fa";

const ScrollBottomBtn = ({
  showScrollButton,
  scrollButtonRef,
  scrollToBottom,
  isNotify,
}: any) => {
  return (
    <>
      {showScrollButton && (
        <div className="sticky z-50 flex justify-end w-full bottom-4 ">
          <button
            ref={scrollButtonRef}
            onClick={scrollToBottom}
            className={`w-[30px] h-[30px] text-white ${
              isNotify && "bg-white"
            } bg-gray-500 rounded-full`}
          >
            <FaAngleDown
              size={24}
              className={`${isNotify && "text-red-500"} mx-auto`}
            />
          </button>
        </div>
      )}
    </>
  );
};

export default ScrollBottomBtn;
