import React, { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import CreatorCard from "../shared/CreatorCard";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import SubText from "components/shared/SubText";
import CreatorService from "services/creators/CreatorService";
import TVChanneSkeleton from "components/skeleton/TVChanneSkeleton";

const CreatorCardCarousal = () => {
  const [creators, setCreators] = useState([]);
  const [isLoading, setIsLoding] = useState<any>(false);

  useEffect(() => {
    setIsLoding(true);
    CreatorService.getNewAllCreators()
      .then((res) => {
        setIsLoding(false);
        setCreators(res.data);
        // console.log(creators);
      })
      .catch((e) => {
        setIsLoding(false);
        console.log(e);
      });
  }, []);

  return (
    <div>
      {!isLoading && (
        <>
          <div className="flex mt-10 justify-between w-[95%] max-sm:w-full  ">
            {creators && creators?.length > 0 && (
              <>
                <SubText text="Creators" className="font-bold max-sm:ml-4" />
                <Link to={RouteName.SeeAllCreatorsRoute}>
                  <p className="text-sm font-bold cursor-pointer red-text place-self-end">
                    {""} See all
                  </p>
                </Link>
              </>
            )}
          </div>

          <div className="mx-auto">
            <Swiper
              slidesPerView={6}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                350: {
                  slidesPerView: 2,
                },
                500: {
                  slidesPerView: 3,
                },
                1100: {
                  slidesPerView: 4,
                },
                1300: {
                  slidesPerView: 6,
                },
                1500: {
                  slidesPerView: 7,
                },
              }}
              modules={[Pagination, Navigation]}
              navigation={true}
              className="w-full mx-auto mt-5 mb-5 mySwiper"
            >
              {creators &&
                creators?.map((value: any, i) => (
                  <SwiperSlide key={i}>
                    <div className="w-full mx-auto">
                      <CreatorCard creator={value} isLoading={isLoading} />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </>
      )}
      {isLoading && <TVChanneSkeleton />}
    </div>
  );
};

export default CreatorCardCarousal;
