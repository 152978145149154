import { FaFacebookF, FaInstagramSquare, FaSoundcloud, FaPaperclip, FaTwitter, FaYoutube } from "react-icons/fa";

export const ProfileSocialMedia=[ 
    {   title : "soundcloud_url",
        icon: FaSoundcloud
    },
    {   title: "instagram_url",
        icon: FaInstagramSquare
    },
    {
        title: "youtube_url",
        icon: FaYoutube
    },
    {
        title: "twitter_url",
        icon: FaTwitter
    },
    {
        title: "facebook_url",
        icon: FaFacebookF
    },
    {
        title: "website_url",
        icon: FaPaperclip
    }
]
