import React from "react";
import ChatUser from "./ChatUser";
import NoMessagesComponent from "./NoMessagesComponent";

function ChatList({ chatList, setSelectedChat, setChatIsOpen }: any) {
  return (
    <>
      {chatList && chatList?.length > 0 ? (
        chatList?.map((data: any, index: number) => (
          <ChatUser
            key={index}
            chatData={data}
            setSelectedChat={setSelectedChat}
            setChatIsOpen={setChatIsOpen}
          />
        ))
      ) : (
        <NoMessagesComponent />
      )}
    </>
  );
}

export default ChatList;
