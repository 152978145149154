import { ChatContext } from "context/ChatContext";
import { IUnreadChat } from "hooks/useUserChatWebSocket";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserChatService from "services/chatService/UserChatService";
import ChatHeaderOpenChat from "./ChatHeaderOpenChat";
import UserChatBody from "./UserChatBody";
import UserChatInput from "./UserChatInput";
import { ChatStatusButton } from "./ChatStatusButton";
import { initilaizeChats, updateChatStatus } from "redux/UserChatRedux";

function OpenChat({
  sidePanel = false,
  setChatIsOpen,
  selectedChat,
  setSelectedChat,
}: any) {
  const dispatch = useDispatch<any>();
  const {
    messages,
    createNewChat,
    sendMessage,
    notifyTyping,
    showChatProgress,
    latestChat,
    changeChatStatus,
    changeMessageStatus,
    updatedMessageStatus,
    setUpdatedMessageStatus,
    setUnreadChats,
    chatUpdateBlockStatus,
  } = useContext(ChatContext);
  const { allChats } = useSelector((state: any) => state?.userChat);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [chatMessages, setChatMessages] = useState<any>();
  const [inputValue, setInputValue] = useState("");
  const [firstMessage, setFirstMessage] = useState("");
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showTyping, setShowTyping] = useState(false);
  const [pagination, setPagination] = useState(1);
  useEffect(() => {
    if (updatedMessageStatus.length > 0) {
      if (updatedMessageStatus[0] === selectedChat.id) {
        const updatedChat = {
          ...chatMessages,
          messages: [
            ...(chatMessages?.messages || []).map((chat: any) => {
              if (!chat.is_read) {
                return { ...chat, is_read: true };
              }
              return chat;
            }),
          ],
        };
        setChatMessages(updatedChat);
        setUpdatedMessageStatus([]);
      }
    }
  }, [updatedMessageStatus]);

  useEffect(() => {
    if (messages?.chat_id == selectedChat?.id) {
      const updatedChat = {
        ...chatMessages,
        messages: [...(chatMessages?.messages || []), messages],
      };
      setChatMessages(updatedChat);
      if (chatMessages != null) {
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
    }

    setUnreadChats((prevChats: IUnreadChat[]) => {
      return prevChats.filter((chat: IUnreadChat) => {
        return chat.chatId != selectedChat.id;
      });
    });
  }, [messages]);

  useEffect(() => {
    // scrollToBottom();
    if (
      chatMessages &&
      chatMessages.messages?.length > 0 &&
      chatMessages.messages[chatMessages.messages?.length - 1]?.user_id !=
        loggedInUser?.id &&
      !chatMessages.messages[chatMessages.messages?.length - 1]?.is_read &&
      selectedChat?.status !== "rejected" &&
      selectedChat?.block_status !== "blocked"
    ) {
      changeMessageStatus(selectedChat?.id, selectedChat?.receiver?.id);
    }
  }, [chatMessages]);

  useEffect(() => {
    const chatId = selectedChat?.id;
    if (
      showChatProgress?.chatId == chatId &&
      showChatProgress?.onChange &&
      showChatProgress?.userId != loggedInUser?.id
    ) {
      setShowTyping(true);
      nearScrollToBottom(200);
    } else {
      setShowTyping(false);
    }
  }, [showChatProgress]);

  const getChatMessages = async (limit: number, page: number) => {
    if (selectedChat?.id != 0) {
      setChatMessages(null);
      UserChatService.getMessages(selectedChat?.id, limit, page).then(
        (res: any) => {
          let updated_messages = {
            ...res,
            messages: res?.messages?.reverse(),
          };
          setChatMessages(updated_messages);

          setTimeout(() => {
            scrollToBottom();
          }, 100);
        }
      );
    } else {
      setChatMessages([]);
    }
  };

  // fetch messages
  useEffect(() => {
    scrollToTop();
    getChatMessages(50, pagination);
  }, [selectedChat]);

  const sendMessageData = (message: string) => {
    // console.log("selectedChat : ", selectedChat);
    if (selectedChat && selectedChat?.id !== 0) {
      const chatId = selectedChat?.id;
      const receiverId = selectedChat?.receiver?.id;

      sendMessage(chatId, receiverId, message);
      setFirstMessage(message);
    } else {
      setFirstMessage(message);
      createNewChat(selectedChat?.receiver?.id, selectedChat?.chat_name);
    }
    setInputValue("");
  };

  useEffect(() => {
    if (latestChat && selectedChat?.id === 0) {
      const a = selectedChat;

      const chatIndex = allChats?.findIndex((chat: any) => {
        return chat.id == latestChat?.id;
      });

      if (chatIndex !== -1) {
        let updatedChats = [...allChats];
        const updatedChat = {
          ...updatedChats[chatIndex],
          latest_message: { ...latestChat, content: firstMessage },
          id: latestChat.id,
          pivot: {
            user_id: loggedInUser?.id,
            chat_id: latestChat.id,
          },
        };

        updatedChats[chatIndex] = updatedChat;

        setSelectedChat(updatedChat);
        dispatch(initilaizeChats(updatedChats));
      }

      sendMessage(latestChat?.id, selectedChat?.receiver?.id, firstMessage);
    }
  }, [latestChat]);

  const loadMoreMessages = () => {
    UserChatService.getMessages(selectedChat?.id, 50, pagination + 1).then(
      (res: any) => {
        let updated_messages = {
          ...res,
          messages: [
            ...res?.messages?.reverse(),
            ...(chatMessages?.messages || []),
          ],
        };
        setChatMessages(updated_messages);
        setPagination(pagination + 1);
      }
    );
  };

  const scrollToBottom = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        top: 0, // Scroll to the top
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const nearScrollToBottom = (threshold: number) => {
    const container = containerRef.current;
    if (container) {
      // const threshold = 500; // pixels from the bottom
      const currentScrollPosition =
        container.scrollTop + container.clientHeight;
      const maxScrollPosition = container.scrollHeight;

      if (maxScrollPosition - currentScrollPosition <= threshold) {
        container.scrollTo({
          top: maxScrollPosition,
          behavior: "smooth",
        });
      }
    }
  };
  const handleApproveChat = (status: string) => {
    changeChatStatus(selectedChat.id, selectedChat.receiver.id, status);
    // console.log("update chat status : ", status);
    setSelectedChat({
      ...selectedChat,
      status: status,
    });
    // dispatch(updateChatStatus({ chatId: selectedChat?.id, status: status }));
  };

  const updateBlockChatStatusFromOpenChat = (status: "active" | "blocked") => {
    const res = chatUpdateBlockStatus(selectedChat.id, status);
    // console.log(res);
    const { block_status, block_by, updated_at, ...otherData } = selectedChat;
    setSelectedChat({
      ...otherData,
      block_status: status,
      block_by:
        status === "blocked"
          ? [...block_by, loggedInUser?.id]
          : block_by.filter((id: number) => id !== loggedInUser?.id),
      updated_at: updated_at,
    });
  };
  return (
    <div
      className={`w-full text-white ${
        sidePanel ? `h-[95vh]` : `h-[85vh] sm:rounded-md`
      } overflow-y-scroll scrollbar-hide flex flex-col `}
    >
      <ChatHeaderOpenChat
        sidePanel={sidePanel}
        receiver={selectedChat?.receiver}
        setSelectedChat={setSelectedChat}
        setChatIsOpen={setChatIsOpen}
        handleApproveChat={handleApproveChat}
        chatStatus={selectedChat?.status}
        chatData={selectedChat}
        updateBlockChatStatusFromOpenChat={updateBlockChatStatusFromOpenChat}
      />

      {/* display messages */}
      <div
        ref={containerRef}
        className="flex-1 overflow-y-auto bg-[var(--Color1E1E1E)] scrollbar-hide"
        style={{ maxHeight: "calc(100% - 40px)" }}
      >
        <UserChatBody
          sidePanel={sidePanel}
          chatMessages={chatMessages}
          showChatProgress={showTyping}
          loadMoreMessages={loadMoreMessages}
        />
      </div>

      {/* user input */}
      {(selectedChat?.status === "approved" &&
        selectedChat.block_status === "active") ||
      (["rejected", "pending"].includes(selectedChat?.status) &&
        selectedChat?.chat_name.split("-")[0] == loggedInUser?.id &&
        selectedChat.block_status !== "blocked") ||
      (selectedChat.block_status === "blocked" &&
        !selectedChat.block_by.includes(loggedInUser?.id)) ? (
        <div
          className={`bottom-1 w-full ${
            !sidePanel && `mt-3`
          } bg-[var(--Color1E1E1E)]`}
        >
          <UserChatInput
            inputValue={inputValue}
            setInputValue={setInputValue}
            sendMessage={sendMessageData}
            userIndividualChat={true}
            notifyTyping={notifyTyping}
            selectedChat={selectedChat}
            sidePanel={sidePanel}
          />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between w-full gap-3 px-5 py-3 mt-2 text-base bg-gray-600">
            <p>
              To proceed, please choose to either accept or reject the chat.
            </p>
            {selectedChat?.status === "pending" &&
              selectedChat?.block_status !== "blocked" && (
                <div className="flex items-center justify-end gap-3 max-sm:gap-1">
                  <ChatStatusButton
                    handleApproveChat={handleApproveChat}
                    className="text-green-600 border-green-600"
                    text="Approve"
                    status="approved"
                  />
                  <ChatStatusButton
                    handleApproveChat={handleApproveChat}
                    className="text-red-600 border-red-600"
                    text="Reject"
                    status="rejected"
                  />
                </div>
              )}
            {selectedChat.status === "rejected" && (
              <ChatStatusButton
                handleApproveChat={handleApproveChat}
                className="text-green-600 border-green-600"
                text="Approve"
                status="approved"
              />
            )}

            {selectedChat.block_status === "blocked" &&
              selectedChat.block_by.includes(loggedInUser?.id) && (
                <ChatStatusButton
                  handleApproveChat={updateBlockChatStatusFromOpenChat}
                  className="text-green-600 border-green-600"
                  text="Unblock"
                  status="active"
                />
              )}
          </div>
        </>
      )}
    </div>
  );
}

export default OpenChat;
