const VideoCard2 = ({ mainText, subText, image }: any) => {
  return (
    <div className="w-[80%] mx-auto mt-[100px] relative z-20 max-sm:mt-[30px] cursor-default">
      <div className="flex items-center justify-center gap-2 white-text max-sm:flex-col max-md:flex-col">
        {/* left section */}
        <div className="w-[50%] max-sm:w-[100%] max-md:w-[100%]">
          <img
            className="z-20 object-cover h-[300px] max-sm:h-[200px] object-center w-[100%] rounded-2xl shadow-xl scale-90 hover:scale-100 hover:duration-500"
            alt="hero"
            src={image}
          />
        </div>
        {/* right section */}
        <div className="flex flex-col items-center justify-end text-center w-[50%] md:items-start max-sm:w-full md:text-right max-md:w-full pl-10 max-sm:p-0 max-md:p-0">
          <h1 className="title-font mb-4 text-[28px] white-text w-[100%] font-[700] max-sm:text-[20px]">
            {mainText}
          </h1>

          <div className="mt-2 gray-text">
            <div className=" mb-3 text-[18px] font-[400] max-sm:text-[15px]">
              <p className="">{subText}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard2;
