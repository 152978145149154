import React from "react";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";

function NoMessagesComponent() {
  return (
    <>
      <div className="flex flex-col items-center justify-center h-full">
        <HiOutlineChatBubbleLeftRight size={50} className="text-gray-400" />
        <p className="mt-5 text-lg text-center text-gray-400">No messages</p>
      </div>
    </>
  );
}

export default NoMessagesComponent;
