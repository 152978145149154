import { RouteName } from "constant/routeNames";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { Tooltip as ReactTooltip } from "react-tooltip";

import SocialMediaPopUp from "components/SocialMediaPopUp/SocialMediaPopUp";
import PopUpModel from "components/PopUpModel";
import FollowButton from "pages/userProfile/shared/FollowButton";
import PrivateAccount from "pages/userProfile/shared/PrivateAccount";
import UserProfileShareBtn from "pages/userProfile/shared/UserProfileShareBtn";
import UserEditProfileButton from "pages/userProfile/shared/UserEditProfileButton";
import UserMessageBtn from "./UserMessageBtn";
import SocialMediaBtn from "./SocialMediaBtn";
import FollowFollowingText from "./FollowFollowingText";
import FollowFollowingSection from "./FollowFollowingSection";
import ProfileDetails from "./ProfileDetails";
import UserProfileQRBtn from "pages/userProfile/shared/UserProfileQRBtn";
import QRPopUp from "components/qrPopUp/QRPopUp";
import ConnectTvBtn from "./ConnectTvBtn";
import ConnectTvPopUp from "components/ConnectTv/TvPopUp/ConnectTvPopUp";

function ProfileHeroSection({ user, isLoading, playlists }: any) {
  const baseUrl = window.location.origin;
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [follow, setFollow] = useState<boolean>(false);
  const [updatedUser, setUpdatedUser] = useState<any>(user);
  const currentUrl = baseUrl + window.location.pathname;
  const [showModal, setShowModal] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [showTVModal, setShowTVModal] = useState(false);

  const openModal = () => {
    // closeDownPlaylist();
    setShowModal(true);
  };

  const closeModal = () => {
    // closeDownPlaylist();
    setShowModal(false);
  };

  const openQRModal = () => {
    // closeDownPlaylist();
    setShowQRModal(true);
  };

  const closeQRModal = () => {
    // closeDownPlaylist();
    setShowQRModal(false);
  };

  const openConnectTvModel = () => {
    setShowTVModal(true);
  };

  const closeConnectTvModel = () => {
    setShowTVModal(false);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta name="title" content={user?.name} />
          <meta name="description" content={user?.bio} />

          <meta name="twitter:title" content={user?.name} />
          <meta name="twitter:description" content={user?.bio} />
          <meta name="twitter:image" content={user?.artwork_url} />

          <meta property="og:title" content={user?.name} />
          <meta property="og:description" content={user?.bio} />
          <meta property="og:image:secure_url" content={user?.artwork_url} />
        </Helmet>
      </HelmetProvider>
      <div className="w-full max-lg:h-[20vh] max-sm:h-[80vh]">
        <div
          className="relative shadow-lg h-[30vh] max-lg:h-[20vh] max-sm:h-full"
          style={{
            // backgroundImage: `url(${user.artwork_url})`,
            backgroundImage: `url(${user?.artwork_url})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="flex flex-col justify-between h-full px-5 py-10 bg-gradient-to-t from-black to-black/50">
            <div className="flex justify-end w-full ">
              <UserProfileShareBtn openModal={openModal} />
              {/* <UserProfileQRBtn openQRModal={openQRModal} user={user} /> */}
              <ConnectTvBtn openConnectTvModel={openConnectTvModel} />
            </div>

            {/* profile details */}
            <div className="sm:hidden">
              <ProfileDetails
                user={user}
                updatedUser={updatedUser}
                setUpdatedUser={setUpdatedUser}
                follow={follow}
                setFollow={setFollow}
                playlists={playlists}
              />
            </div>

            <FollowFollowingSection
              user={user}
              className="justify-end max-sm:hidden"
            />
          </div>

          {showModal && (
            <div className="z-50">
              <PopUpModel
                isOpen={showModal}
                onClose={closeModal}
                width={"800px"}
                isClosedWhenClickOutside={true}
              >
                <SocialMediaPopUp shareUrl={currentUrl} />
              </PopUpModel>
            </div>
          )}

          {showQRModal && (
            <div className="z-50">
              <PopUpModel
                isOpen={showQRModal}
                onClose={closeQRModal}
                width={"800px"}
                isClosedWhenClickOutside={true}
              >
                <QRPopUp />
              </PopUpModel>
            </div>
          )}

          {showTVModal && (
            <PopUpModel
              isOpen={showTVModal}
              onClose={closeConnectTvModel}
              width={"800px"}
              isClosedWhenClickOutside={true}
            >
              <ConnectTvPopUp />
            </PopUpModel>
          )}
        </div>

        <ReactTooltip
          id="copy-profile-link"
          place="bottom"
          content="Copy to clipboard!"
        />
      </div>

      {/* profile details */}
      <div className="max-sm:hidden">
        <ProfileDetails
          user={user}
          updatedUser={updatedUser}
          setUpdatedUser={setUpdatedUser}
          follow={follow}
          setFollow={setFollow}
        />
      </div>
    </>
  );
}

export default ProfileHeroSection;
