import SubText from "components/shared/SubText";
import Podcastcard from "pages/podcastPage/shared/Podcastcard";
import { useSelector } from "react-redux";

function CreatorHomePodcastGrid({ podcasts, setIsSelected, onClick }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const handleSelectSeeAll = () => {
    setIsSelected(6);
    if (onClick) onClick();
  };

  return (
    <div>
      {/* card section */}

      {podcasts?.length !== 0 && (
        <>
          <div className="flex justify-between mt-10 ml-3 max-sm:ml-0">
            <SubText text="Podcast" className="font-bold" />
            {podcasts?.length > 3 && (
              <p
                onClick={handleSelectSeeAll}
                className="font-[500] text-[15px] text-[#F50537] cursor-pointer mr-10 max-sm:mr-3"
              >
                See all
              </p>
            )}
          </div>

          <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3  max-sm:mx-auto max-sm:w-full max-sm:gap-4">
            {podcasts?.length > 0 &&
              podcasts?.slice(0, 3)?.map((podcast: any, i: any) => (
                <div key={i} className="max-sm:ml-[-15px] ml-3">
                  <Podcastcard podcast={podcast} user={loggedInUser} />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}

export default CreatorHomePodcastGrid;
