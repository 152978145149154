import { BackTop } from "antd";
import { ChatContext } from "context/ChatContext";
import { useUserChatWebSocket } from "hooks/useUserChatWebSocket";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initilaizeChats } from "redux/UserChatRedux";
import AppRouter from "routes/Router";
import useQuantcast from "useQuantcast";
import "styles/googleTranslater.css";
import Hotjar from "hotjar";

function App() {
  const CHAT_SOCKET_URL = process.env.REACT_APP_CHAT_SERVER;
  useQuantcast();
  const dispatch = useDispatch<any>();
  const { allChats } = useSelector((state: any) => state?.userChat);
  const [isNewChat, setIsNewChat] = useState<boolean>(false);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const {
    messages,
    createNewChat,
    sendMessage,
    notifyTyping,
    changeChatStatus,
    unreadChats,
    setUnreadChats,
    showChatProgress,
    initialScroll,
    latestChat,
    handleLatestChat,
    changeMessageStatus,
    updatedMessageStatus,
    setUpdatedMessageStatus,
    chatUpdateBlockStatus,
  } = useUserChatWebSocket(
    loggedInUser?.access_token,
    loggedInUser?.id,
    CHAT_SOCKET_URL ? `${CHAT_SOCKET_URL}/user-chat` : "",
    // "ws://localhost:3001/user-chat",
    () => setIsNewChat(true)
  );
  const [selectedChat, setSelectedChat] = useState<any>();
  const [selectedChatMain, setSelectedChatMain] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusUpdatedChat, setStatusUpdatedChat] = useState<any>();
  useEffect(() => {
    if (statusUpdatedChat) {
      const chatIndex = allChats?.findIndex((chat: any) => {
        return chat.id == statusUpdatedChat?.id;
      });
      if (chatIndex !== -1) {
        let updatedChats = [...allChats];
        const updatedChat = {
          ...updatedChats[chatIndex],
          latest_message: statusUpdatedChat?.latest_message,
        };

        updatedChats[chatIndex] = updatedChat;
        dispatch(initilaizeChats(updatedChats));
      }
    }
  }, [statusUpdatedChat]);

  useEffect(() => {
    const chatIndex = allChats?.findIndex((chat: any) => {
      return chat.id == messages?.chat_id;
    });

    if (chatIndex !== -1) {
      let updatedChats = [...allChats];

      const convertedLatsetMsg = {
        ...messages,
        chat_id: parseInt(messages?.chat_id),
      };

      const updatedChat = {
        ...updatedChats[chatIndex],
        latest_message: convertedLatsetMsg,
      };

      updatedChats[chatIndex] = updatedChat;

      setIsLoading(true);
      dispatch(initilaizeChats(updatedChats));
      setIsLoading(false);
    }
  }, [messages]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Hotjar/>
      <BackTop />

      <ChatContext.Provider
        value={{
          messages,
          createNewChat,
          sendMessage,
          notifyTyping,
          changeChatStatus,
          showChatProgress,
          initialScroll,
          latestChat,
          selectedChat,
          setSelectedChat,
          isLoading,
          setIsLoading,
          selectedChatMain,
          setSelectedChatMain,
          isNewChat,
          handleLatestChat,
          unreadChats,
          setUnreadChats,
          changeMessageStatus,
          updatedMessageStatus,
          setUpdatedMessageStatus,
          setStatusUpdatedChat,
          chatUpdateBlockStatus,
        }}
      >
        <AppRouter />
      </ChatContext.Provider>
    </>
  );
}

export default App;
