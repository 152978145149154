import axios from "axios";
import authHeader from "utils/auth-header";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;

const BASE_URL = `${process.env.REACT_APP_BACKEND_SERVER}`;
const CHAT_SOCKET_URL = process.env.REACT_APP_CHAT_SERVER;

const approveChat = async (chatId: number) => {
  const response = await axios({
    method: "post",
    url: `${BASE_URL}/chats/${chatId}/status`,
    data: {
      status: "approved",
    },
    headers: authHeader(),
  });
  return response.data;
};

const getMessages = async (chatId: number, limit = 50, page = 1) => {
  const response = await axios({
    method: "post",
    url: `${BASE_URL}/chats/${chatId}/messages`,
    // data: {
    //   status: "approved",
    // },
    data: {
      page: page,
      limit: limit,
    },
    headers: authHeader(),
  });
  return response.data;
};

const updateBlockStatus = async (chatId: number, status: "blocked" | "active") => {
  const data = {
    block_status: status,
  };

  try {
    const response = await axios({
      method: "post",
      url: `${BASE_URL}/chats/${chatId}/block-status`,
      headers: authHeader(),
      data: data,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const UserChatService = {
  approveChat,
  getMessages,
  updateBlockStatus
};

export default UserChatService;
