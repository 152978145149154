import { RouteName } from "constant/routeNames";
import { RiPlayList2Line } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

function PlaylistCard({ playlist, creatorData }: any) {
  let streamKey = playlist?.first_video_stream_key;
  // console.log(playlist);
  const navigate = useNavigate();
  const handlePlaylist = () => {
    if (streamKey) {
      navigate(
        RouteName.CreatorPlaylistRoute.replace(
          ":slug",
          creatorData?.general_info.slug
        ).replace(":stream", streamKey)
      );
    }
  };
  return (
    <div
      className={playlist?.first_video_stream_key ? "" : "hidden"}
      onClick={handlePlaylist}
    >
      {/* <Link
        to={RouteName.CreatorPlaylistRoute.replace(":slug", video?.slug)
          .replace(":id", playlist.playListInfo.id)
          .replace(":stream", streamKey)}
      > */}
      <div className="w-[98%] cursor-pointer px-3 py-3 rounded-md bg-[#1a1919] hover:bg-slate-800 hover:shadow-xl shadow-lg max-sm:my-0">
        {/* <p className="mb-4 text-base text-white">{headText}</p> */}
        {/* <p className="text-2xl font-[400] my-2 text-white max-xl:my-1">
          Most tuned in this week
        </p> */}

        {/* <p className="text-[12px] font-[400] text-[#7B9DB4] mt-[5px] max-sm:mt-1s">
          {data.description}
        </p> */}

        <div
          className="relative w-[100%] rounded-lg h-[200px] bg-img white-text max-sm:h-[180px] max-sm:w-full"
          style={{
            backgroundImage: `url(${playlist?.first_video_thumbnail_url})`,
            backgroundSize: "cover", // Apply the "cover" property
            backgroundPosition: "center center", // Center the image
          }}
        >
          <div className="absolute inset-x-0 bottom-0 flex justify-between p-2 bg-gradient-to-t from-black to-black/50 h-[30px]">
            <div className="flex items-center h-full">
              <div  data-tooltip-id={playlist?.title?.length > 40 ? `add-song-to-favorite-${playlist?.title}`: ""}>
                <p className="text-[15px] text-white ml-2 my-1 leading-4 font-[700]">
                  {playlist?.title.length > 40
                    ? `${playlist?.title.slice(0, 40)}...`
                    : playlist?.title}
                </p>
                <div className="z-50 text-sm ">
                  <ReactTooltip
                    place="top"
                    id={`add-song-to-favorite-${playlist?.title}`}
                    content={playlist?.title}
                    style={{ width: "180px" }}
                  />
                </div>
              </div>
            </div>
            <div>
              <button className="flex items-center h-full p-1 rounded-full">
                <RiPlayList2Line className="w-5 h-5 text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Link> */}
    </div>
  );
}

export default PlaylistCard;
