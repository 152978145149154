import LazyLoadImg from "components/LazyLoadImg";
import { RouteName } from "constant/routeNames";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RiUserAddFill, RiUserFollowFill } from "react-icons/ri";
import { Tooltip as ReactTooltip } from "react-tooltip";

const CreatorCard = ({ isLoading, creator }: any) => {
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  const subscribeCreator = favorite?.favorite_artists?.find(
    (subscribe: any) => subscribe?.id === creator?.id
  );

  return (
    <>
      {!isLoading && (
        <div>
          {creator && (
            <Link
              to={RouteName.VideoCreatorHomeRoute.replace(
                ":slug",
                `@${creator.slug}`
              )}
            >
              <div className="flex items-center justify-center w-full mx-auto text-center">
                <div className="relative flex-col items-center justify-center mx-auto rounded-full ">
                  {/* image */}
                  <div className=" w-[116px] h-[116px] items-center justify-center rounded-full overflow-hidden mx-auto">
                    <LazyLoadImg
                      imgSrc={creator?.profile_image}
                      imgAlt={"Artist"}
                      imgHeight={"116px"}
                      imgWidth={"116px"}
                      className="w-[116px] h-full drop-shadow-2xl object-cover overflow-hidden !rounded-full"
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                        borderRadius: "5px",
                        objectFit: "cover",
                      }}
                    />
                    {subscribeCreator?.id === creator?.id ? (
                      <>
                        <div
                          className="absolute flex justify-end w-[116px] bottom-7"
                          data-tooltip-id="creator-following"
                        >
                          <RiUserFollowFill className="text-2xl text-black bg-[var(--green)] rounded-full w-10 h-10 p-2" />
                          <div className="z-50 text-sm ">
                            <ReactTooltip
                              place="top"
                              id="creator-following"
                              content="Following"
                              style={{ width: "100px", borderRadius: "20px" }}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="absolute flex justify-end w-[116px] bottom-7"
                          data-tooltip-id="creator-follow"
                        >
                          <RiUserAddFill className="text-2xl text-black bg-[var(--green)] rounded-full w-10 h-10 p-2" />
                          <div className="z-50 text-sm ">
                            <ReactTooltip
                              place="top"
                              id="creator-follow"
                              content="Follow"
                              style={{ width: "100px", borderRadius: "20px" }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <p
                    className=" mt-2 text-white font-[600] text-[14px] w-full justify-center text-center"
                    // dangerouslySetInnerHTML={{ __html: creator?.title }}
                  >
                    {creator?.name}
                  </p>
                </div>
              </div>
            </Link>
          )}
        </div>
      )}
    </>
  );
};

export default CreatorCard;
