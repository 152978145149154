import React, { useState } from "react";
import SubText from "components/shared/SubText";
import TextSkeleton from "components/skeleton/videoDetailsSkeleton/TextSkeleton";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";

function Details({ video, isLoading }: any) {
  // console.log(video);
  return (
    <>
      {!isLoading && (
        <div className="mt-10">
          {/* top section */}
          <div className="flex">
            <div className="w-full">
              {/* Description */}
              {/* <div>
                <SubText text="More info" />
                <p className="mt-10 text-base font-normal text-white w-[70%] text-justify max-sm:w-full max-sm:text-left max-sm:pr-4 max-lg:pr-4 max-lg:w-full max-xl:pr-4 max-xl:w-full">
                  {video?.description
                    .replace(/&amp;/g, "&")
                    .replace(/&rsquo;/g, "’")
                    .replace(/&nbsp;/g, "")}
                </p>
              </div> */}
              {/* extra details */}
              {/* <div className="mt-10 w-[60%]">
            <p className="text-lg font-medium text-white">Content advisory</p>
            <p className="mt-3 text-base font-normal text-white">
              Alcohol use, substance use, violence, foul language
            </p>
          </div> */}
              <div className=" w-[60%] max-sm:w-full">
                {video?.directors?.length > 0 && (
                  <>
                    <SubText text="Directors" />
                    <div className="flex ml-1 max-sm:block">
                      {video?.directors?.length > 0 && (
                        <div className="mr-3 mt-3">
                          <span className="mt-3 text-base font-normal text-white ">
                            {video?.directors?.map(
                              (director: any, i: number) => (
                                <>
                                 <Link to={RouteName.VideoCreatorHomeRoute.replace( ":slug",
                                  `@${director?.slug}`)}
                                    className="hover:underline"
                                  >
                                    {director?.name
                                      ?.replace(/&amp;/g, "&")
                                      .replace(/&rsquo;/g, "’")
                                      .replace(/&nbsp;/g, "")}
                                  </Link>
                                  {i !== video?.directors?.length - 1 && " , "}
                                </>
                              )
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="mt-10 w-[60%] max-sm:w-full">
                {video?.producers?.length > 0 && (
                  <>
                    <SubText text="Producers" />
                    <div className="flex ml-1 max-sm:block">
                      {video?.producers?.length > 0 && (
                        <div className="mr-3 mt-3">
                          <span className="mt-3 text-base font-normal text-white ">
                            {video?.producers?.map(
                              (produce: any, i: number) => (
                                <>
                                  <Link
                                    to={RouteName.VideoCreatorHomeRoute.replace(
                                      ":slug",
                                      `@${produce?.slug}`
                                    )}
                                    className="hover:underline"
                                  >
                                    {produce?.name
                                      ?.replace(/&amp;/g, "&")
                                      .replace(/&rsquo;/g, "’")
                                      .replace(/&nbsp;/g, "")}
                                  </Link>
                                  {i !== video?.producers?.length - 1 && " , "}
                                </>
                              )
                            )}
                          </span>
                          {/* <span className="mt-3 text-base font-normal text-white">
                            {video?.producers
                              ?.map((produce: any, i: number) =>
                                produce?.name
                                  ?.replace(/&amp;/g, '&')
                                  .replace(/&rsquo;/g, '’')
                                  .replace(/&nbsp;/g, '')
                              )
                              .join(',  ')}
                          </span> */}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="mt-10 w-[60%] block max-sm:w-full">
                {video?.actors?.length > 0 && (
                  <>
                    <SubText text="Starring" />
                    <div className="ml-1 flex max-sm:block">
                      {video?.actors?.length > 0 && (
                        <div className="mr-3 mt-3">
                          <span className="mt-3 text-base font-normal text-white">
                            {video?.actors?.map((actor: any, i: number) => (
                              <>
                                <Link
                                  to={RouteName.VideoCreatorHomeRoute.replace(
                                    ":slug",
                                    `@${actor?.slug}`
                                  )}
                                  className="hover:underline"
                                >
                                  {actor?.name
                                    ?.replace(/&amp;/g, "&")
                                    .replace(/&rsquo;/g, "’")
                                    .replace(/&nbsp;/g, "")}
                                </Link>
                                {i !== video?.actors?.length - 1 && " , "}
                              </>
                            ))}
                          </span>
                          {/* <span className="mt-3 text-base font-normal text-white">
                            {video?.actors
                              ?.map((actor: any, i: number) =>
                                actor?.name
                                  ?.replace(/&amp;/g, '&')
                                  .replace(/&rsquo;/g, '’')
                                  .replace(/&nbsp;/g, '')
                              )
                              .join(',  ')}
                          </span> */}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading && <TextSkeleton />}
    </>
  );
}

export default Details;
