import { HiArrowLeft } from "react-icons/hi";
import AddToQueue from "components/shared/AddToQueue";
import PlayButton from "components/shared/PlayButton";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import PlaylistService from "services/music/PlaylistService";
import { useDispatch, useSelector } from "react-redux";
import { addSongsToQueue } from "redux/AudioPlayerRedux";
import {
  triggerSuccessNotification,
  triggerWarningNotification,
} from "utils/notification";
import { Notification } from "constant/notifications";
import { toast } from "react-toastify";
import ConfirmationPopUp from "components/Pop-Ups/ConfirmationPopUp";
import PopUpModel from "components/PopUpModel";
import LazyLoadImg from "components/LazyLoadImg";
import MusicTable from "./shared/MusicTable";
import EditPlaylist from "./playlist/EditPlaylist";
import { FaLock, FaLockOpen, FaPlus } from "react-icons/fa";
import { TbTrashX } from "react-icons/tb";
import { RouteName } from "constant/routeNames";
import MusicPlaylistSkeleton from "components/skeleton/MusicPlaylistSkeleton";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SubText from "components/shared/SubText";
import PlaylistCoursel from "./playlist/PlaylistCoursel";
import AddPlaylistToFav from "./shared/AddPlaylistToFav";
import { Tooltip as ReactTooltip } from "react-tooltip";

function MusicPlaylistPage() {
  const { id } = useParams();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const favorites = useSelector((state: any) => state?.user?.userFavorites);
  const dispatch = useDispatch<any>();
  const [playlist, setPlaylist] = useState<any>();
  const [removedSongId, setRemovedSongId] = useState<any>();
  const navigate = useNavigate();
  const [userPlaylists, setUserPlaylists] = useState([]);
  const [favorite, setFavorite] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(false);
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;
  const popRef = useRef<HTMLDivElement | null>(null);

  const [publicPlaylists, setPublicPlaylists] = useState<any>([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    PlaylistService.getPlaylistByPlaylistId(id).then((res) => {
      setIsLoading(false);
      setPlaylist(res.data);
      // console.log(res.data);
    });
  }, [id]);

  useEffect(() => {
    if (loggedInUser) {
      const favoritesIds = favorites?.loved?.data?.map((item: any) => item?.id);
      setFavorite(favoritesIds);
    }
  }, [favorites, loggedInUser]);

  useEffect(() => {
    if (loggedInUser) {
      PlaylistService.getUserPlaylists().then((res) => {
        setUserPlaylists(res.data.data);
      });
    }
  }, [loggedInUser]);

  // remove song from the list
  useEffect(() => {
    // This useEffect updates playlist state
    const filteredData = playlist?.songs?.filter(
      (song: any) => song.id !== removedSongId
    );
    setPlaylist((prevPlaylist: any) => {
      return {
        ...prevPlaylist,
        songs: filteredData,
      };
    });
  }, [removedSongId]);

  const addAlbumToExistingQueue = () => {
    if (playlist?.songs?.length > 0) {
      dispatch(addSongsToQueue(playlist?.songs));
      triggerSuccessNotification(Notification.SongsAddedToQueue);
    } else {
      triggerWarningNotification(Notification.NoSongs);
    }
  };

  // delete playlist
  const handleDeletePlaylist = async () => {
    const formDataObj = new FormData();
    formDataObj.append("playlist_id", playlist?.id);

    await PlaylistService.deletePlaylist(formDataObj)
      .then((response: any) => {
        if (response.status === 200) {
          toast.success("Playlist Deleted");
          navigate(-1);
        } else {
          toast.error("There is an issue when deleting playlist");
        }
      })
      .catch(() => {
        toast.error("An error occurred. Please try again.");
      });
  };

  //public playlist carousal
  useEffect(() => {
    setIsLoading(true);
    PlaylistService.getPublicPlaylists()
      .then((res: any) => {
        setIsLoading(false);
        // console.log(res.data.data);
        if (playlist) {
          const filteredData = res.data.data?.filter(
            (item: any) => item?.id !== playlist?.id
          );
          // console.log(filteredData);
          setPublicPlaylists(filteredData);
        }
        // if (loggedInUser) {
        //   const filteredData = res.data.data.filter(
        //     (item: any) => item?.user?.id !== loggedInUser.id
        //   );
        //   setPublicPlaylists(filteredData);
        // } else {
        //   setPublicPlaylists(res.data.data);
        // }
      })
      .catch((e) => {
        setIsLoading(false);
        // console.log(e);
      });
  }, [playlist]);

  // delete playlist confirmation
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {/* {`${name} - Ayozat`} */}
            {playlist?.title ? `${playlist?.title} - AYOZAT` : "AYOZAT"}
          </title>
          <meta name="title" content={playlist?.title} />
          <meta name="description" content={playlist?.description} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={playlist?.title} />
          <meta name="twitter:description" content={playlist?.description} />
          <meta name="twitter:image" content={playlist?.artwork_url} />
          <meta name="twitter:site" content="AYOZAT" />
          <meta
            name="twitter:url"
            content={`${FE_URL}/music/artist/${playlist?.id}`}
          />

          <meta property="og:title" content={playlist?.title} />
          <meta property="og:image" content={playlist?.artwork_url} />
          <meta property="og:description" content={playlist?.description} />
          <meta
            property="og:url"
            content={`${FE_URL}/music/playlist/${playlist?.id}`}
          />
          <meta property="og:type" content="website" />
        </Helmet>
      </HelmetProvider>
      {!isLoading && (
        <div>
          {/* album hero section */}
          <div
            className="relative h-auto overflow-hidden"
            style={{
              backgroundImage: `url(${playlist?.artwork_url})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="p-3 full-backdrop-bottom">
              {/* back arrow icon */}
              <div
                className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer`}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <HiArrowLeft />
              </div>
              <div className="flex items-end mt-16 max-sm:block">
                {/* album image */}
                {/* <img
              src={playlist?.artwork_url}
              className="album-page-image"
              alt=""
            /> */}
                <div className="mt-5 max-sm:hidden">
                  <LazyLoadImg
                    imgSrc={playlist?.artwork_url}
                    imgAlt={"first artist"}
                    imgHeight={"224px"}
                    imgWidth={"224px"}
                    className="album-page-image"
                    style={{
                      borderRadius: "3px",
                      backgroundSize: "cover",
                    }}
                  />
                </div>
                {/* album details */}
                <div className="ml-10 max-sm:ml-0">
                  {/* <p className="my-1 text-base font-medium fafafa">
                <span className="underline">Jhons</span> Playlist
              </p> */}

                  {loggedInUser && loggedInUser?.id === playlist?.user?.id && (
                    <>
                      {playlist?.visibility === 1 ? (
                        <p className="text-[12px] text-[#7B9DB4] w-[630px] flex items-center gap-2 my-2 max-sm:w-[80%] cursor-default max-sm:my-2 max-sm:ml-1">
                          <span>
                            <FaLockOpen />
                          </span>
                          Public Playlist
                        </p>
                      ) : (
                        <p className="text-[12px] text-[#7B9DB4] w-[630px] flex items-center gap-2 my-2 max-sm:w-[80%] cursor-default max-sm:my-2 max-sm:ml-1">
                          <span>
                            <FaLock />
                          </span>
                          Private Playlist
                        </p>
                      )}
                    </>
                  )}

                  <h1 className="my-1 text-5xl font-bold font-secular fafafa cursor-default max-sm:text-4xl">
                    {playlist?.title}
                  </h1>
                  {/* Playlist owner */}
                  <Link
                    to={RouteName.UserProfile.replace(
                      "*",
                      playlist?.user?.username
                    )}
                  >
                    <p className="text-[15px] text-[#7B9DB4] w-[630px] flex items-center gap-2 my-2 max-sm:w-[80%] max-sm:my-2 max-sm:ml-1">
                      {playlist?.user?.name && (
                        <LazyLoadImg
                          imgSrc={playlist?.user?.artwork_url}
                          imgAlt={"first artist"}
                          imgHeight={"20px"}
                          imgWidth={"20px"}
                          className="album-page-image"
                          style={{
                            borderRadius: "100%",
                            backgroundSize: "cover",
                          }}
                        />
                      )}
                      {playlist?.user?.name}
                    </p>
                  </Link>
                  <p className="text-[12px] text-[#7B9DB4] w-[630px] my-2 max-sm:w-[80%] max-sm:my-2 max-sm:ml-1">
                    {playlist?.description !== "undefined" &&
                      playlist?.description}
                  </p>

                  <p className="my-1 text-lg font-semibold cursor-default Color7B9DB4">
                    {playlist?.songs
                      ? playlist?.songs?.length === 0
                        ? `No Songs`
                        : playlist?.songs?.length > 1
                        ? `${playlist?.songs?.length} Songs`
                        : `${playlist?.songs?.length} Song`
                      : ``}
                    {/* <span className="ml-8">4 hrs</span> */}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center my-5 mt-8 max-sm:grid max-sm:grid-cols-2">
            <div className="flex items-center max-sm:mt-2 max-sm:grid max-sm:grid-cols-1">
              {playlist && (
                <>
                  {playlist?.songs?.length > 0 ? (
                    <PlayButton
                      songs={playlist?.songs}
                      playListId={playlist?.id}
                    />
                  ) : (
                    <div
                      onClick={() => {
                        navigate(RouteName.Music.replace("/*", ""));
                      }}
                    >
                      <p className="flex items-center  text-[12px] mx-6 text-base font-bold text-white cursor-pointer max-sm:text-[12px] hover:text-red-600 duration-200 ease-in-out">
                        <FaPlus className="mr-3" /> Add Songs
                      </p>
                    </div>
                  )}
                </>
              )}
              {playlist?.songs?.length > 0 ? (
                <div className="flex " onClick={addAlbumToExistingQueue}>
                  <AddToQueue
                    toolTiId={`new-released-card`}
                    playlist={playlist}
                  />
                </div>
              ) : (
                <></>
              )}

              {/* add playlist to favorites */}
              <AddPlaylistToFav playlist={playlist} Icon={true} />
            </div>

            {loggedInUser && (
              <div className="flex items-center justify-end gap-1 max-sm:grid max-sm:grid-cols-1 max-sm:gap-2">
                <div
                  className="px-5 text-white "
                  ref={popRef}
                  data-tooltip-id={`${`delete-playlist`}-${playlist?.id}`}
                >
                  {loggedInUser?.id === playlist?.user?.id && (
                    <button
                      className="cursor-pointer hover:text-red-600 hover:border-red-600 max-sm:text-[12px] max-sm:w-full max-sm:py-3"
                      onClick={openModal}
                    >
                      <TbTrashX className="text-4xl" />
                    </button>
                  )}

                  {showModal && (
                    <div className="z-50">
                      <PopUpModel
                        isOpen={showModal}
                        onClose={closeModal}
                        width={"800px"}
                      >
                        <ConfirmationPopUp
                          title={"Delete Playlist"}
                          text={`Are you sure you want to delete ${playlist?.title} ?`}
                          executableFunction={handleDeletePlaylist}
                          onClose={closeModal}
                          executableFunctionButtonText={"Confirm"}
                        />
                      </PopUpModel>
                    </div>
                  )}
                  <div className="text-sm">
                    <ReactTooltip
                      // id={`song-card-${song?.id}`}
                      id={`${`delete-playlist`}-${playlist?.id}`}
                      place="top"
                      content="Delete Playlist"
                    />
                  </div>
                </div>

                <div className=" max-sm:flex max-sm:justify-start">
                  {playlist && loggedInUser?.id === playlist?.user?.id && (
                    <EditPlaylist
                      playlist={playlist}
                      setPlaylist={setPlaylist}
                      setUserPlaylists={setUserPlaylists}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="my-5 mt-10">
            <MusicTable
              playlistId={playlist?.id}
              songs={playlist && playlist?.songs}
              userPlaylists={userPlaylists}
              setUserPlaylists={setUserPlaylists}
              favorite={favorite}
              removeFromPlaylist={
                loggedInUser?.id === playlist?.user?.id ? true : false
              }
              setRemovedSongId={setRemovedSongId}
            />
          </div>

          {/* public playlists */}
          <div className="mt-5 mb-20">
            <div className="flex flex-row items-center justify-between mt-5">
              <SubText text="Discover Playlists" className="font-light" />
              <Link to={RouteName.SeeAllDiscoverPlaylistsRoute}>
                <p className="font-[500] pr-4 text-[15px] text-[#F50537] cursor-pointer">
                  See all
                </p>
              </Link>
            </div>
            <PlaylistCoursel
              playlists={publicPlaylists}
              isLoading={isLoading}
            />
          </div>
        </div>
      )}
      {isLoading && <MusicPlaylistSkeleton />}
    </>
  );
}

export default MusicPlaylistPage;
