import { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination } from "swiper";
import PickupCard from "pages/videoPage/home/shared/PickupCard";

import "swiper/css";
import "swiper/css/pagination";
import SubText from "components/shared/SubText";
import { useDispatch, useSelector } from "react-redux";
import { removeLastWatch } from "redux/VideoPlayerRedux";
import VideoService from "services/video/VideoService";
import PickupSkeletons from "components/skeleton/PickupSkeletons";
function PickupCarousel() {
  const dispatch = useDispatch<any>();
  const { lastWatched } = useSelector((state: any) => state?.video);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const videosRef = useRef<any[]>([]);
  const [finalLastWatched, setFinalLastWatched] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    if (loggedInUser) {
      VideoService.getUserLastWatch().then((res) => {
        let apiLastWatch: any[] = [
          ...res.watching_movies,
          ...res.watching_tv_shows,
        ];

        //  get api data and redux data, then merged them, alway get tha latest values from the redux
        const mergedArray: any[] = [
          ...apiLastWatch.map(
            (apiItem) =>
              videosRef.current.find(
                (reduxItem: any) => reduxItem?.id === apiItem.id
              ) || apiItem
          ),
          ...videosRef.current.filter(
            (reduxItem: any) =>
              !apiLastWatch.some((apiItem) => apiItem.id === reduxItem?.id)
          ),
        ];

        setFinalLastWatched(mergedArray);
        setIsLoading(false);
      });
    } else {
      setFinalLastWatched(videosRef.current);
      setIsLoading(false);
    }
  }, [videosRef.current]);

  useEffect(() => {
    setIsLoading(true);
    const data: any[] = lastWatched.filter((d: any) => {
      if (
        (d.currentTime / d.duration) * 100 < 95 &&
        (d.currentTime / d.duration) * 100 > 0
      ) {
        // console.log('save video');
        return d;
      } else {
        // console.log('delete video');
        dispatch(removeLastWatch(d.id));
      }
    });

    videosRef.current = data;
    setIsLoading(false);
  }, [lastWatched]);


  return (
    <>
      {!isLoading && (
        <>
          {finalLastWatched.length > 0 && (
            <>
              <div className="flex justify-between w-[95%]  max-sm:w-full">
                <SubText
                  text="Pick up where you left off"
                  className="font-bold max-sm:ml-4"
                />
              </div>

              <Swiper
                slidesPerView={7}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  380: {
                    slidesPerView: 1,
                  },
                  700: {
                    slidesPerView: 2,
                  },
                  1000: {
                    slidesPerView: 2,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                  1400: {
                    slidesPerView: 4,
                  },
                  1600: {
                    slidesPerView: 4,
                  },
                  1700: {
                    slidesPerView: 5,
                  },
                }}
                modules={[Pagination, Navigation]}
                className="w-full mx-auto mt-5 mb-5 mySwiper"
                navigation={true}
              >
                {finalLastWatched?.map((card: any, i: number) => (
                  <SwiperSlide key={i}>
                    <div className="mx-3 max-sm:mx-1">
                      <PickupCard {...card} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          )}
        </>
      )}
      {isLoading && <PickupSkeletons />}
    </>
  );
}

export default PickupCarousel;
