import { useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { store } from "redux/store";
import { addNewChat, initilaizeChats, updateChats, updateChatStatus } from "redux/UserChatRedux";
import UserChatService from "services/chatService/UserChatService";

export interface IUnreadChat {
  chatId: number;
  isRead: boolean;
  count: number;
}

export const useUserChatWebSocket = (
  token: string,
  userId: number,
  wsUrl: string,
  onNewMessage: () => void
) => {
  const { allChats } = useAppSelector((state: any) => state?.userChat);
  const dispatch = useDispatch<any>();
  const [messages, setMessages] = useState<any>();
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [showChatProgress, setChatProgress] = useState();
  const [initialScroll, setInitialScroll] = useState<boolean>(false);
  const [latestChat, setLatestChat] = useState<any>();
  const [unreadChats, setUnreadChats] = useState<IUnreadChat[]>([]);
  const [updatedMessageStatus, setUpdatedMessageStatus] = useState<any[]>([]);

  useEffect(() => {
    const socket = new WebSocket(wsUrl);
    setWs(socket);

    socket.onopen = () => {
      console.log("Connected to WebSocket");
      socket.send(
        JSON.stringify({ event: "joinRoom", data: { userId, token } })
      );

      setInitialScroll(true);
    };

    socket.onmessage = (event) => {
      const messageData = JSON.parse(event.data);
      const { event: eventType, data } = messageData;

      if (eventType === "get-user-chats") {
        console.log(data);
        const unreadChatsArray: IUnreadChat[] = data?.data
          ?.filter((chat: any) => chat?.unread_count > 0)
          .map((chat: any) => ({
            chatId: chat.id,
            isRead: false,
            count: chat.unread_count,
          }));
        setUnreadChats(unreadChatsArray);
        dispatch(initilaizeChats(data.data));
      }

      if (eventType === "new-chat-created") {
        const newChat = {...data.data , status:"pending", block_status: 'active', block_by: []};
        setLatestChat(newChat);
        dispatch(addNewChat(newChat));
      }
      if (eventType === "updateMessages") {
        setMessages(data.data);
        // console.log("websocket messages : ", data.data);
        onNewMessage();
        if (userId !== data.data.user.id) {
          // Find the chat in the chats array

          const state = store.getState();
          const allChats = state.userChat.allChats;
          const chatInChats = allChats.find(
            (chat: any) => chat.id == data.data.chat_id
          );
          if (
            chatInChats &&
            chatInChats?.status !== "rejected" &&
            chatInChats?.block_status !== "blocked"
          ) {
            console.log("update unread chats");
            setUnreadChats((prevUnread: IUnreadChat[]) => {
              const chatExists = prevUnread.find(
                (chat) => chat.chatId === data.data.chat_id
              );
              if (chatExists) {
                // If it exists, map through the array and update the count
                return prevUnread.map(
                  (chat) =>
                    chat.chatId === data.data.chat_id
                      ? { ...chat, count: chat.count + 1 } // Update count if chatId matches
                      : chat // Keep the other chats unchanged
                );
              } else {
                // If it doesn't exist, add a new chat with count 1
                return [
                  ...prevUnread,
                  { chatId: data.data.chat_id, isRead: false, count: 1 },
                ];
              }
            });
          }
        }
      }
      if (eventType === "chat-onChangeReceive") {
        // console.log("User is typing:", data);
        if (data.userId === userId) {
          setChatProgress({ ...data, onchange: false });
        } else {
          setChatProgress(data);
        }
      }

      if (eventType === "updated-chat-status") {
        console.log("Chat status changed:", data?.data);
        const approvedChat = data?.data.chat;
        const state = store.getState();
        const allChats = state.userChat.allChats;
        const updatedChat = allChats.map((chat: any) => {
          if (chat.id === approvedChat.id) {
            const { status, updated_at, ...otherData } = chat;
            return {
              ...otherData,
              status: approvedChat.status,
              updated_at: updated_at,
            };
          }
          return chat;
        });
        // Use a Set to filter out duplicates
        const uniqueChats = Array.from(
          new Map(updatedChat.map((chat: any) => [chat.id, chat])).values()
        );
        // dispatch(initilaizeChats(uniqueChats));
        const statusChanged = {
          chatId: approvedChat.id,
          status: approvedChat.status,
        }
        dispatch(updateChatStatus(statusChanged));
      }

      if (eventType === "updated-message-status") {
        // console.log("Chat status changed:", data?.data);
        setUpdatedMessageStatus(data?.data?.chat_ids);
      }
    };

    socket.onclose = () => {
      console.log("Disconnected from WebSocket");
    };

    return () => {
      socket.close();
    };
  }, [userId, token]);

  const createNewChat = (receiverId: number, chatName: string) => {
    // console.log(receiverId, chatName);
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          event: "new-chat",
          data: { receiverId, chatName, userId, token },
        })
      );
    }
  };

  const sendMessage = (chatId: number, receiverId: number, message: string) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          event: "send-message",
          data: { chatId, message, receiverId, userId, token },
        })
      );

      notifyTyping(chatId, receiverId, false);
    }
  };

  const notifyTyping = (chatId: number, receiverId: number, state: boolean) => {
    // console.log("User is typing:", userId, state, receiverId);
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          event: "chat-onChange",
          data: { chatId, onChange: state, userId, receiverId },
        })
      );
    }
  };
  const changeChatStatus = (
    chatId: number,
    receiverId: number,
    status: string
  ) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          event: "update-chat-status",
          data: { chatId, userId, receiverId, token, status: status },
        })
      );
    }
  };
  const handleLatestChat = (object: any) => {
    setLatestChat(object);
  };

  const changeMessageStatus = (chatId: number, receiverId: number) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          event: "update-message-status",
          data: { chatId: [chatId], userId, receiverId, token },
        })
      );
    }
  };
  const chatUpdateBlockStatus = (
    chatId: number,
    status: "blocked" | "active"
  ) => {
    console.log(chatId, status);
    UserChatService.updateBlockStatus(chatId, status).then((res) => {
      if (res.message === "Block status updated") {
        const statusChangedChat = allChats.find(
          (chat: any) => chat.id === chatId
        );
        const { block_status, block_by, updated_at, ...otherData } =
          statusChangedChat;
        const updatedChat = {
          ...otherData,
          block_status: status,
          block_by:
            status === "blocked"
              ? [...block_by, userId]
              : block_by.filter((id: number) => id !== userId),
          updated_at: updated_at,
        };
        dispatch(updateChats(updatedChat));
      }
    });
    return { chatId, status };
  };

  return {
    messages,
    createNewChat,
    sendMessage,
    notifyTyping,
    changeChatStatus,
    unreadChats,
    setUnreadChats,
    showChatProgress,
    initialScroll,
    latestChat,
    handleLatestChat,
    changeMessageStatus,
    updatedMessageStatus,
    setUpdatedMessageStatus,
    chatUpdateBlockStatus,
  };
};
