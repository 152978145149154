import { useEffect, useState } from "react";
import UserPageOverView from "../UserPageOverView";
import UserVideoPage from "../UserVideoPage";
import UserMusicPage from "../UserMusicPage";
import UserPodcastPage from "../UserPodcastPage";
import UserRadioPage from "../UserRadioPage";
import UserPageNavButton from "components/shared/UserPageNavButton";
import PlaylistService from "services/music/PlaylistService";
import UserPlaylistPage from "../UserPlaylistPage";
import { useSelector } from "react-redux";
import UserPayperviewPage from "../UserPayperviewPage";
import PaidSubscription from "../PaidSubscription";
import UserSubscribedPage from "../UserSubscribedPage";
// import UserPageNavButton from "components/shared/UserPageNavButton";

function UserProfileButtonSection({
  userPlaylist,
  isLoadingPlaylist,
  // haveData,
  user,
  favorite,
  ppvEvent,
}: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [isSelected, setIsSelected] = useState<any>(1);
  const [userPlaylists, setUserPlaylists] = useState([]);
  const [userPublicPlaylists, setUserPublicPlaylists] = useState([]);
  const [, setIsLodingPlaylist] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<any>(false);
  const enabled = localStorage.getItem("ppv_enabled");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (user && user?.id !== loggedInUser?.id && user?.activity_privacy === 1) {
      setComponents(visibleButtons[0]);
      setIsSelected(4);
    }
  }, [user, loggedInUser]);

  useEffect(() => {
    setIsLoading(true);
    setIsLodingPlaylist(true);
    if (user) {
      if (loggedInUser && user.id === loggedInUser.id) {
        PlaylistService.getUserPlaylists().then((res) => {
          setIsLoading(false);
          setIsLodingPlaylist(false);
          // setUserPlaylists(res.data.data);
          // console.log(res.data.data);
          setUserPublicPlaylists(res.data.data);
        });
      } else {
        PlaylistService.getPublicPlaylistByUserId(user?.id).then((res) => {
          setIsLoading(false);
          setIsLodingPlaylist(false);
          // setUserPlaylists(res.data.data);
          console.log(res.data.data);
          setUserPublicPlaylists(res.data.data);
        });
      }
    }
  }, [user]);

  // useEffect(() => {
  //   if (user) {
  //     if (user.id !== loggedInUser.id) {
  //       PlaylistService.getPublicPlaylists()
  //         .then((res: any) => {
  //           // console.log(res.data.data);
  //           const filteredData = res.data.data.filter(
  //             (item: any) =>
  //               item?.visibility === 1 && item?.user?.id === user?.id
  //           );
  //           setUserPublicPlaylists(filteredData);
  //         })
  //         .catch((e) => {
  //           // setIsLoding(false);
  //           // console.log(e);
  //         });
  //     }
  //   }
  // }, [user]);

  const favoriteMoviesIds = favorite?.favorite_movies?.map(
    (item: any) => item.id
  );
  const favoriteTvSeriesIds = favorite?.favorite_tv_series?.map(
    (item: any) => item.id
  );
  const favoriteEpisodesIds = favorite?.favorite_publication_videos?.map(
    (item: any) => item.id
  );
  const favoriteMusicIds = favorite?.loved?.data?.map((item: any) => item.id);
  const favoritePodcastsIds = favorite?.favorite_podcasts?.map(
    (item: any) => item.id
  );
  const favoriteRadioIds = favorite?.favorite_radio_stations?.map(
    (item: any) => item.id
  );
  const favoriteArtistIds = favorite?.favorite_artists?.map(
    (item: any) => item.id
  );
  const favoritePpvEventsIds = favorite?.favorite_ppv_events?.map(
    (item: any) => item.id
  );
  const favoriteCreatorIds = favorite?.followed_creators?.map(
    (item: any) => item?.id
  );
  const favoritePublicPlaylistsIds = favorite?.favorite_public_playlists?.map(
    (item: any) => item?.id
  );

  const handleDropdownSelect = (page: any) => {
    setIsSelected(page.id);
    setComponents(page);
    setSelectedDropdownValue(page.name);
    setIsDropdownOpen(false);
  };

  const handleOverviewOrVideoClick = (pagesId: number) => {
    setIsSelected(pagesId);
    setSelectedDropdownValue(null);
  };

  const filterAndCapitalizeButtons = (favorite: any) => {
    const filteredButtons = [
      {
        id: 1,
        component: UserPageOverView,
        name: "Overview",
        data: [
          ...(favorite?.favorite_movies || []),
          ...(favorite?.favorite_tv_series || []),
          ...(favorite?.favorite_publication_videos || []),
          ...(favorite?.loved?.data || []),
          ...(favorite?.favorite_artists || []),
          ...(favorite?.favorite_podcasts || []),
          ...(favorite?.favorite_radio_stations || []),
          ...(favorite?.favorite_ppv_events || []),
          // ...(favorite?.followed_creators || []),
          ...(favorite?.favorite_public_playlists || []),
        ],
      },
      {
        id: 2,
        component: UserVideoPage,
        name: "Videos",
        data: [
          ...(favorite?.favorite_movies || []),
          ...(favorite?.favorite_tv_series || []),
          ...(favorite?.favorite_publication_videos || []),
        ],
      },
      {
        id: 3,
        component: UserMusicPage,
        name: "Music",
        data: [...(favorite?.loved?.data || [])],
      },
      {
        id: 4,
        component: UserPlaylistPage,
        name: "Playlists",
        data: [
          ...(userPlaylist || []),
          ...(userPublicPlaylists || []),
          ...(favorite?.favorite_public_playlists || []),
        ],
      },
      {
        id: 5,
        component: UserPodcastPage,
        name: "Podcast",
        data: [...(favorite?.favorite_podcasts || [])],
      },
      {
        id: 6,
        component: UserRadioPage,
        name: "Radio",
        data: [...(favorite?.favorite_radio_stations || [])],
      },
      {
        id: 7,
        component: UserPayperviewPage,
        name: "PPV",
        data: [...(favorite?.favorite_ppv_events || [])],
      },
      {
        id: 8,
        component: PaidSubscription,
        name: "Paid Subscription",
        data: ppvEvent,
      },
      {
        id: 9,
        component: UserSubscribedPage,
        name: "Artists",
        data: [...(favorite?.favorite_artists || [])],
      },
    ];

    const visibleButtons = filteredButtons.filter((button) => {
      if (button.data) {
        // Check if the data is an array and has elements
        return Array.isArray(button.data) && button.data.length > 0;
      } else {
        return true; // Always show HOME and About buttons
      }
    });

    return visibleButtons.map((button) => ({
      ...button,
      name: button.name,
    }));
  };

  // Usage
  const visibleButtons = filterAndCapitalizeButtons(favorite);
  const [visibleButtonsLoading, setVisibleButtonsLoading] =
    useState<boolean>(true);
  const [components, setComponents] = useState<any>(null);

  useEffect(() => {
    if (visibleButtons?.length > 0 && visibleButtonsLoading) {
      setIsSelected(visibleButtons[0].id);
      setComponents({
        id: visibleButtons[0].id,
        component: visibleButtons[0].component,
        name: visibleButtons[0].name,
        data: visibleButtons[0].data,
      });
      setVisibleButtonsLoading(false);
    }
  }, [visibleButtons]);

  const handleLessClick = () => {
    setIsExpanded(false);
    setComponents(visibleButtons[0]);
    setIsSelected(visibleButtons[0].id);
  };

  return (
    <div>
      <div className="flex my-3 ml-6 max-sm:hidden max-sm:grid-cols-3 max-md:grid max-md:grid-cols-4 max-lg:grid-cols-4 max-sm:mx-0 max-sm:gap-2">
        <>
          {user &&
          user?.id !== loggedInUser?.id &&
          user?.activity_privacy === 1 ? (
            <>
              <UserPageNavButton
                pages={visibleButtons[0]}
                setIsSelected={setIsSelected}
                isSelected={isSelected}
                selected={true}
                setComponents={setComponents}
              />
            </>
          ) : (
            <>
              {enabled === "1" ? (
                <>
                  {visibleButtons &&
                    visibleButtons?.slice(0, 3).map((pages: any, i: number) => (
                      <div key={i} className="mr-2">
                        <UserPageNavButton
                          pages={pages}
                          setIsSelected={setIsSelected}
                          isSelected={isSelected}
                          selected={pages?.id === isSelected ? true : false}
                          setComponents={setComponents}
                        />
                      </div>
                    ))}
                  {visibleButtons?.length > 3 && !isExpanded && (
                    <div className="mr-2">
                      <button
                        onClick={() => setIsExpanded(true)}
                        className={`flex justify-center items-center min-w-[120px] h-[40px] p-3 text-center text-white max-sm:p-2 max-sm:ml-0 my-1 rounded-full border-[1px] hover:border-[#f30543] hover:text-[#f30543]`}
                      >
                        More
                      </button>
                    </div>
                  )}
                  {isExpanded &&
                    visibleButtons &&
                    visibleButtons?.slice(3).map((pages: any, i: number) => (
                      <div key={i} className="mr-2">
                        <UserPageNavButton
                          pages={pages}
                          setIsSelected={setIsSelected}
                          isSelected={isSelected}
                          selected={pages?.id === isSelected ? true : false}
                          setComponents={setComponents}
                        />
                      </div>
                    ))}
                  {isExpanded && (
                    <div className="mr-2">
                      <button
                        onClick={handleLessClick}
                        className={`flex justify-center items-center min-w-[120px] h-[40px] p-3 text-center text-white rounded-full bg-[#a8022e] max-sm:p-2 border-[1px] max-sm:ml-0  my-1 hover:border-[#f30543] border-[#f30543]`}
                      >
                        Less
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {visibleButtons?.slice(0, 3).map((pages: any, i: number) => (
                    <div key={i} className="mr-2">
                      <UserPageNavButton
                        pages={pages}
                        setIsSelected={setIsSelected}
                        isSelected={isSelected}
                        selected={pages?.id === isSelected ? true : false}
                        setComponents={setComponents}
                      />
                    </div>
                  ))}
                  {visibleButtons.length > 3 && !isExpanded && (
                    <div className="mr-2">
                      <button
                        onClick={() => setIsExpanded(true)}
                        className={`flex justify-center items-center min-w-[120px] h-[40px] p-3 text-center text-white max-sm:p-2 max-sm:ml-0 my-1 rounded-full border-[1px] hover:border-[#f30543] hover:text-[#f30543]`}
                      >
                        More
                      </button>
                    </div>
                  )}
                  {isExpanded &&
                    visibleButtons
                      ?.slice(2, -2)
                      .map((pages: any, i: number) => (
                        <div key={i} className="mr-2">
                          <UserPageNavButton
                            pages={pages}
                            setIsSelected={setIsSelected}
                            isSelected={isSelected}
                            selected={pages?.id === isSelected ? true : false}
                            setComponents={setComponents}
                          />
                        </div>
                      ))}
                  {isExpanded && (
                    <div className="mr-2">
                      <button
                        onClick={handleLessClick}
                        className={`flex justify-center items-center min-w-[120px] h-[40px] p-3 text-center  text-white rounded-full bg-[#a8022e] max-sm:p-2 border-[1px] max-sm:ml-0  my-1 hover:border-[#f30543] border-[#f30543]`}
                      >
                        Less
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      </div>
      {/* mobile view */}
      <div className="my-3 max-sm:flex sm:hidden max-sm:grid-cols-3">
        {visibleButtons?.slice(0, 2).map((pages: any, i: number) => (
          <div key={i} className="mr-2">
            <UserPageNavButton
              pages={pages}
              setIsSelected={setIsSelected}
              isSelected={isSelected}
              selected={pages?.id === isSelected ? true : false}
              setComponents={setComponents}
              onClick={() => handleOverviewOrVideoClick(pages.id)} // Add onClick event here
            />
          </div>
        ))}
        {visibleButtons?.length > 2 && (
          <>
            <button
              onClick={() => {
                setIsDropdownOpen(!isDropdownOpen);
              }}
              className={`flex justify-center items-center min-w-[120px] h-[40px] p-3 text-center my-1 rounded-full border-[1px] ${
                selectedDropdownValue === "Paid Subscription"
                  ? "button-green text-black font-bold text-sm"
                  : selectedDropdownValue
                  ? "text-white rounded-full bg-[#a8022e]"
                  : "text-white hover:border-[#f30543] hover:text-[#f30543]"
              }`}
            >
              {selectedDropdownValue ? selectedDropdownValue : "More"}
            </button>
          </>
        )}
        {isDropdownOpen && (
          <div className="right-0 mt-10 absolute z-10 min-w-[120px] p-2 bg-white rounded-lg">
            {visibleButtons?.slice(2).map((page: any, i: number) => (
              <div
                key={i}
                className="p-2 hover:bg-[#2c2c2c] rounded-lg cursor-pointer"
                onClick={() => handleDropdownSelect(page)}
              >
                {page.name}
              </div>
            ))}
          </div>
        )}
      </div>

      <div id="section1" className="text-white mb-[150px]">
        {components && (
          <components.component
            user={user}
            favoriteData={favorite}
            favoriteMoviesIds={favoriteMoviesIds}
            favoriteTvSeriesIds={favoriteTvSeriesIds}
            favoriteEpisodesIds={favoriteEpisodesIds}
            favoriteMusicIds={favoriteMusicIds}
            favoritePodcastsIds={favoritePodcastsIds}
            favoriteRadioIds={favoriteRadioIds}
            favoriteArtistIds={favoriteArtistIds}
            userPlaylists={userPlaylists}
            // setUserPlaylists={setUserPlaylists}
            isLoading={isLoading}
            userPublicPlaylists={userPublicPlaylists}
            userPlaylist={userPlaylist}
            isLoadingPlaylist={isLoadingPlaylist}
            purchased={ppvEvent}
            favoritePpvIds={favoritePpvEventsIds}
            favoriteCreatorIds={favoriteCreatorIds}
            favoritePublicPlaylistsIds={favoritePublicPlaylistsIds}
          />
        )}
      </div>
    </div>
  );
}

// const pageName = [
//   {
//     id: 1,
//     component: UserPageOverView,
//     name: "Overview",
//   },
//   {
//     id: 2,
//     component: UserVideoPage,
//     name: "Videos",
//   },
//   {
//     id: 3,
//     component: UserMusicPage,
//     name: "Music",
//   },
//   {
//     id: 4,
//     component: UserPlaylistPage,
//     name: "Playlists",
//   },
//   {
//     id: 5,
//     component: UserPodcastPage,
//     name: "Podcast",
//   },
//   {
//     id: 6,
//     component: UserRadioPage,
//     name: "Radio",
//   },
//   {
//     id: 7,
//     component: UserPayperviewPage,
//     name: "PPV",
//   },
//   {
//     id: 8,
//     component: PaidSubscription,
//     name: "Paid Subscription",
//   },
//   {
//     id: 9,
//     component: UserSubscribedPage,
//     name: "Creators",
//   },
// ];

export default UserProfileButtonSection;
