import React from "react";
import FollowFollowingText from "./FollowFollowingText";
import { RouteName } from "constant/routeNames";

function FollowFollowingSection({ user, className }: any) {
  return (
    <div className={`flex items-center mt-auto ${className && className}`}>
      <div className="flex items-center">
        <FollowFollowingText
          route={RouteName.UserProfile.replace(
            "/*",
            RouteName.UserFollowersRoute.replace(":username", user?.username)
          )}
          count={user?.follower_count}
          text="Follower"
        />
        <p className="mx-4 text-2xl text-slate-500">|</p>
        <FollowFollowingText
          route={RouteName.UserProfile.replace(
            "/*",
            RouteName.UserFollowingsRoute.replace(":username", user?.username)
          )}
          count={user?.following_count}
          text="Following"
        />
      </div>
    </div>
  );
}

export default FollowFollowingSection;
