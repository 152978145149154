import React, { useState } from "react";
import { FiTv } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Badge, Tooltip } from "antd";
import PopUpModel from "components/PopUpModel";
import ConnectTvPopUp from "../TvPopUp/ConnectTvPopUp";

function ConnectTvNavIcon() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [showTVModal, setShowTVModal] = useState(false);

  const openConnectTvModel = () => {
    setShowTVModal(true);
  };

  const closeConnectTvModel = () => {
    setShowTVModal(false);
  };
  return (
    <>
      {loggedInUser && (
        <>
          <Tooltip title="Connect to the TV">
            <div
              className="ml-5 text-2xl cursor-pointer white-text"
              onClick={openConnectTvModel}
            >
              <FiTv className="text-2xl text-white" />
            </div>
          </Tooltip>

          {showTVModal && (
            <PopUpModel
              isOpen={showTVModal}
              onClose={closeConnectTvModel}
              width={"800px"}
              isClosedWhenClickOutside={true}
            >
              <ConnectTvPopUp />
            </PopUpModel>
          )}
        </>
      )}
    </>
  );
}

export default ConnectTvNavIcon;
