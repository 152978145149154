import { FacebookShareButton } from "react-share";
import { useState } from "react";
import SocialMediaShareBtn from "../SocialMediaShareBtn";
import CopySharebleLink from "../CopySharebleLink";
import TextArea from "antd/es/input/TextArea";

function Facebook({ shareUrl }: any) {
  const [quote, setQuote] = useState();

  return (
    <div className="text-white">
      {/* <p className="mb-3">Add your caption</p> */}
      <p className="my-5">
        Clicking share below will open a new window to share on Facebook.
      </p>

      {/* <TextArea
        showCount
        maxLength={100}
        style={{ height: 150 }}
        onChange={(e: any) => {
          setQuote(e.target.value);
        }}
      /> */}

      {/* <Input
        type={"text"}
        onChange={(e: any) => {
          setQuote(e.target.value);
        }}
      /> */}
      <hr className="my-3 border-[#3e3e3eea]" />

      <div className="social-media-pop-up-btn-section">
        <CopySharebleLink shareUrl={shareUrl} />
        <FacebookShareButton url={shareUrl} quote={quote} hashtag={"#AYOZAT"}>
          <SocialMediaShareBtn />
        </FacebookShareButton>
      </div>
    </div>
  );
}

export default Facebook;
