import SubText from "components/shared/SubText";
import React from "react";
import { FaYoutube } from "react-icons/fa";
import {
  FaInstagram,
  FaSquareFacebook,
  FaSquareTwitter,
  FaTiktok,
} from "react-icons/fa6";

function AboutPage({ creatorData }: any) {
  // console.log("first", creatorData);
  // Date conversion
  const dateString = `${creatorData?.released_at}`;

  const joinDate = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = joinDate.toLocaleDateString("en-US", options);

  return (
    <div className="mt-10 ml-6 max-sm:ml-0">
      {/* top section */}
      <div className="flex">
        <div className="w-[70%] max-sm:w-full">
          {/* Description */}
          {creatorData?.general_info?.description && (
            <div>
              <SubText text="Description" />
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    creatorData?.general_info?.description
                      ?.replace(/&amp;/g, "&")
                      .replace(/&rsquo;/g, "’")
                      .replace(/&ldquo;/g, '"')
                      .replace(/&rdquo;/g, '"')
                      .replace(/&nbsp;/g, "")
                      .replace(/\n/g, "<br/>") || "", // Replace newline with <br/>
                }}
                className="text-base font-normal text-white w-[70%] text-justify mt-5 max-sm:w-[95%] max-sm:text-justify"
              />
            </div>
          )}

          {/* Details */}
          {creatorData?.general_info?.country_name && (
            <div className="mt-12">
              <SubText text="Details" />

              <div className="flex items-center mt-5">
                <p className="mr-3">Location:</p>
                <p className="ml-28">
                  {creatorData?.general_info?.country_name}
                </p>
              </div>
              {/* <hr className="w-[70%] mt-20" /> */}
            </div>
          )}

          {/* Links */}
          {(creatorData?.general_info?.facebook_url ||
            creatorData?.general_info?.twitter_url ||
            creatorData?.general_info?.instagram_url ||
            creatorData?.general_info?.tiktok_url ||
            creatorData?.general_info?.youtube_url) && (
            <div className="mb-20 mt-12">
              <SubText text="Links" />
              <div className="flex gap-10 w-[50%]">
                {creatorData?.general_info?.facebook_url && (
                  <div className="mt-5">
                    {/* <p className="font-semibold">Facebook</p> */}
                    <a
                      href={creatorData?.general_info?.facebook_url}
                      rel="noreferrer nofollow
                      "
                      target="_blank"
                      className="text-3xl text-[var(--red)]"
                    >
                      <FaSquareFacebook />
                    </a>
                  </div>
                )}
                {creatorData?.general_info?.instagram_url && (
                  <div className="mt-5">
                    {/* <p className="font-semibold">Instagram</p> */}
                    <a
                      href={creatorData?.general_info?.instagram_url}
                      rel="noreferrer nofollow"
                      target="_blank"
                      className="text-3xl text-[var(--red)]"
                    >
                      <FaInstagram />
                    </a>
                  </div>
                )}
                {creatorData?.general_info?.twitter_url && (
                  <div className="mt-5">
                    {/* <p className="font-semibold">Instagram</p> */}
                    <a
                      href={creatorData?.general_info?.twitter_url}
                      rel="noreferrer nofollow"
                      target="_blank"
                      className="text-3xl text-[var(--red)]"
                    >
                      <FaSquareTwitter />
                    </a>
                  </div>
                )}
                {creatorData?.general_info?.tiktok_url && (
                  <div className="mt-5">
                    {/* <p className="font-semibold">Instagram</p> */}
                    <a
                      href={creatorData?.general_info?.tiktok_url}
                      rel="noreferrer nofollow"
                      target="_blank"
                      className="text-3xl text-[var(--red)]"
                    >
                      <FaTiktok />
                    </a>
                  </div>
                )}
                {creatorData?.general_info?.youtube_url && (
                  <div className="mt-5">
                    <a
                      href={creatorData?.general_info?.youtube_url}
                      rel="noreferrer nofollow"
                      target="_blank"
                      className="text-3xl text-[var(--red)]"
                    >
                      <FaYoutube />
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
