import SubText from "components/shared/SubText";
import AlbumCard from "pages/musicPage/shared/AlbumCard";

function CreatorHomeAlbumsGrid({ albums, setIsSelected, onClick }: any) {
  const handleSelectSeeAll = () => {
    setIsSelected(4);
    if (onClick) onClick();
  };

  return (
    <>
      {albums?.length !== 0 && (
        <div className="flex justify-between mt-10 ml-3 max-sm:ml-0">
          <SubText text="Albums" className="font-bold" />
          {albums?.length > 6 && (
            <p
              onClick={handleSelectSeeAll}
              className="font-[500] text-[15px] text-[#F50537] cursor-pointer mr-10"
            >
              See all
            </p>
          )}
        </div>
      )}
      <div className="mt-4 ml-3 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
        {albums?.slice(0, 6)?.map((album: any, index: number) => (
          <div key={index}>
            <AlbumCard {...album} />
          </div>
        ))}
      </div>
    </>
  );
}

export default CreatorHomeAlbumsGrid;
