import { useSelector } from "react-redux";

function ChatOptions({
  className,
  chatStatus,
  handleApproveChat,
  updateBlockChatStatusFromOpenChat,
  chatData,
}: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const handleChatStatus = (status: any) => {
    handleApproveChat(status);
  };

  // console.log(chatData);

  return (
    <div
      className={`text-white text-left navbar max-sm:min-h-[150px] submenu-main absolute ${
        className && className
      }`}
    >
      <div className="">
        {chatStatus === "pending" &&
        chatData?.chat_name.split("-")[1] == loggedInUser?.id ? (
          <div className="leading-10">
            <p
              className="flex items-center justify-between sub-menu-item"
              onClick={() => handleChatStatus("approved")}
            >
              Approve
            </p>

            <p
              className="flex items-center justify-between sub-menu-item"
              onClick={() => handleChatStatus("rejected")}
            >
              Reject
            </p>
          </div>
        ) : chatStatus === "pending" &&
          chatData?.chat_name.split("-")[0] == loggedInUser?.id &&
          !chatData.block_by.includes(loggedInUser?.id) ? (
          <p
            className="flex items-center justify-between sub-menu-item"
            onClick={() =>
              updateBlockChatStatusFromOpenChat(
                chatData.block_status === "blocked" ? "active" : "blocked"
              )
            }
          >
            {chatData.block_status === "blocked" ? "Unblock" : "Block"}
          </p>
        ) : (
          <></>
        )}

        {chatStatus === "rejected" && (
          <p
            className="flex items-center justify-between sub-menu-item"
            onClick={() => handleChatStatus("approved")}
          >
            Approve
          </p>
        )}
        {chatStatus === "approved" && chatData.block_status === "active" && (
          <p
            className="flex items-center justify-between sub-menu-item"
            onClick={() =>
              updateBlockChatStatusFromOpenChat(
                chatData.block_status === "blocked" ? "active" : "blocked"
              )
            }
          >
            {chatData.block_status === "blocked" ? "Unblock" : "Block"}
          </p>
        )}

        {chatData.block_by.includes(loggedInUser?.id) && (
          <p
            className="flex items-center justify-between sub-menu-item"
            onClick={() =>
              updateBlockChatStatusFromOpenChat(
                chatData.block_status === "blocked" ? "active" : "blocked"
              )
            }
          >
            {chatData.block_status === "blocked" ? "Unblock" : "Block"}
          </p>
        )}
      </div>
    </div>
  );
}

export default ChatOptions;
