import React, { useEffect, useRef } from "react";

const Paralex = ({ src, alt }: any) => {
  return (
    <div
      className="relative h-[400px] my-10 overflow-hidden w-[80%] mx-auto rounded-lg max-sm:h-[200px] cursor-default"
      style={{
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <img
        className="absolute top-0 left-0 z-20 object-cover w-full h-full transition-transform duration-500"
        alt={alt}
        src={src}
      />
    </div>
  );
};

export default Paralex;
