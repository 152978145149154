import ContentNavbar from "components/ContentNavbar";
import React from "react";
import WelcomePageSidebar from "./welcome/WelcomePageSidebar";
import { contentNavData } from "constant/ContentNavBarData";
import UserChatMain from "./UserChat/UserChatMain";
import { Route, Routes } from "react-router-dom";
import FeaturePages from "layout/FeaturePages";

function UserChat({
  handleClick,
  isActive,
  setIsActive,
  // setPlayerIsActive,
  anyPlayerActive,
}: //

any) {
  return (
    <>
      <Routes>
        <Route
          path=""
          element={
            <FeaturePages
              Content={UserChatMain}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Chat"}
              //
            />
          }
        />
      </Routes>
    </>
    // <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
    //   <ContentNavbar handleClick={handleClick} isActive={isActive} />
    //   <WelcomePageSidebar
    //     isActive={isActive}
    //     navData={contentNavData}
    //     setIsActive={setIsActive}
    //   />
    //   <div
    //     // className={
    //     //   isActive
    //     //     ? "md:pl-[75px] pl-[80px] duration-500 p-2 mt-2 ml-8"
    //     //     : "pl-0 duration-500 p-2 mt-2 ml-5"
    //     // }
    //     className={
    //       isActive
    //         ? "pl-[80px] duration-500 p-2 mt-2 ml-4 max-sm:pl-0 max-sm:ml-2 "
    //         : "pl-0 duration-500 p-2 mt-2 ml-8 max-sm:pl-0 max-sm:ml-2 "
    //     }
    //   >
    //     <UserChatMain />
    //   </div>
    // </div>
  );
}

export default UserChat;
