import { useEffect, useState } from "react";
import { useParams } from "react-router";
import SearchAll from "./SearchAll";
import SearchVideo from "./SearchVideo";
import SearchMusic from "./SearchMusic";
import SearchRadio from "./SearchRadio";
import SearchLiveTv from "./SearchLiveTv";
import SearchPodcast from "./SearchPodcast";
import UserPageNavButton from "components/shared/UserPageNavButton";
import SearchServie from "services/search/SearchService";
import { useSelector } from "react-redux";
import SearchPeoples from "./SearchPeoples";
import SearchPageSkeleton from "components/skeleton/SearchPageSkeleton";
import SearchPlaylist from "./SearchPlaylist";
import SearchPPV from "./SearchPPV";
import SearchCreators from "./SearchCreators";

function SearchResultPage() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const { keyword } = useParams();
  // const getOriginalKeyword = (text: any) => {
  //   return text.replace("-", " ");
  // };

  const [isSelected, setIsSelected] = useState<any>(1);

  const [components, setComponents] = useState<any>(pageName[0]);

  const [allData, setAllData] = useState<any>();

  const [isLoading, setIsLoading] = useState<any>(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const enabled = localStorage.getItem("ppv_enabled");

  useEffect(() => {
    setIsLoading(true);
    setErrorMessage(null);
    if (keyword) {
      SearchServie.searchAll(keyword).then((res) => {
        setIsLoading(false);
        if (res?.message === "The search query value is null or too short") {
          setErrorMessage(res?.message);
        } else {
          setAllData(res);
        }
      });
    }
  }, [keyword]);

  // console.log("Message", errorMessage);

  const filteredPageName = pageName.filter((item) => {
    if (enabled === "1") {
      return true;
    } else {
      return item.id !== 8;
    }
  });

  return (
    <>
      {!isLoading && !errorMessage && (
        <div>
          <p className="text-2xl text-white">
            Search results for : <span className="text-red-600">{keyword}</span>
          </p>

          <div>
            <div className="flex items-center justify-start gap-5 my-5 max-sm:grid max-sm:grid-cols-3 max-xl:grid max-xl:grid-cols-5 max-md:grid max-md:grid-cols-4 max-sm:mx-0 max-sm:gap-2">
              {filteredPageName.map((pages: any, i: number) => (
                <div key={i} className="mr-2">
                  <UserPageNavButton
                    pages={pages}
                    setIsSelected={setIsSelected}
                    isSelected={isSelected}
                    selected={pages.id === isSelected ? true : false}
                    setComponents={setComponents}
                  />
                </div>
              ))}
            </div>

            <div id="section1" className="text-white">
              {
                <components.component
                  user={loggedInUser}
                  data={allData}
                  isLoading={isLoading}
                  enabled={enabled}
                />
              }
            </div>
          </div>
        </div>
      )}
      {isLoading && <SearchPageSkeleton />}

      {!isLoading && errorMessage && (
        <>
          <SearchPageSkeleton />
          <div className="relative z-50 flex justify-center w-full mx-auto text-xl text-gray-600 top-10">
            {errorMessage}
          </div>
        </>
      )}
    </>
  );
}

export default SearchResultPage;

const pageName = [
  {
    id: 1,
    component: SearchAll,
    name: "All",
  },
  {
    id: 2,
    component: SearchLiveTv,
    name: "Live TV",
  },
  {
    id: 3,
    component: SearchVideo,
    name: "Video",
  },
  {
    id: 4,
    component: SearchCreators,
    name: "Creators",
  },
  {
    id: 5,
    component: SearchMusic,
    name: "Music",
  },
  {
    id: 6,
    component: SearchRadio,
    name: "Radio",
  },
  {
    id: 7,
    component: SearchPodcast,
    name: "Podcast",
  },
  {
    id: 8,
    component: SearchPPV,
    name: "PPV",
  },
  {
    id: 9,
    component: SearchPeoples,
    name: "Users",
  },
  // {
  //   id: 9,
  //   component: SearchPlaylist,
  //   name: "Playlists",
  // },
  // {
  //   id: 8,
  //   component: SearchPPV,
  //   name: "Pay per view",
  // },
];
