import SubText from "components/shared/SubText";
import React from "react";
import { ChannelCard } from "pages/liveTvPage/shared/ChannelCard";

function CreatorHomeLiveTVGrid({
  tvChannels,
  setIsSelected,
  onClick,
  musicLength,
}: any) {
  const handleSelectSeeAll = () => {
    setIsSelected(2);
    if (onClick) onClick();
  };

  return (
    <>
      {tvChannels?.length !== 0 && (
        <div className="flex justify-between mt-10 ml-3 max-sm:ml-0">
          <SubText text="Live TV" className="font-bold" />
          {musicLength === 0 ? (
            <>
              {tvChannels?.length > 7 && (
                <p
                  onClick={handleSelectSeeAll}
                  className="font-[500] text-[15px] text-[#F50537] cursor-pointer mr-10"
                >
                  See all
                </p>
              )}
            </>
          ) : (
            <>
              {tvChannels?.length > 4 && (
                <p
                  onClick={handleSelectSeeAll}
                  className="font-[500] text-[15px] text-[#F50537] cursor-pointer mr-10"
                >
                  See all
                </p>
              )}
            </>
          )}
        </div>
      )}
      {musicLength === 0 ? (
        <>
          <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
            {tvChannels?.slice(0, 7)?.map((channel: any, index: number) => (
              <div key={index}>
                <ChannelCard
                  data={channel}
                  img={channel?.thumbnail_url}
                  className=""
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="mt-4 mb-4 w-[95%] grid grid-cols-4 gap-10 max-sm:grid-cols-1 max-md:grid-cols-2 max-lg:grid-cols-2 max-xl:grid-cols-4 max-2xl:grid-cols-4 max-[1700px]:grid-cols-4 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
            {tvChannels?.slice(0, 4)?.map((channel: any, index: number) => (
              <div key={index}>
                <ChannelCard
                  data={channel}
                  img={channel?.thumbnail_url}
                  className=""
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default CreatorHomeLiveTVGrid;
